import React, { FC, useState } from 'react'

import {
  DataTable,
  PaginationNav,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react'
import { observer } from 'mobx-react-lite'

import { useLoader, usePagination } from '@src/library/utils/hooks'

import { formatDate } from '@helpers/date'

import { $filter } from '@library/providers/StoreProvider'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

import { devPlanService } from '@services'

interface IProps {
  departments: string[]
}

const PepList: FC<IProps> = ({ departments }) => {
  const {
    page,
    setPage,
    totalItems,
    setPagingCount,
    sortBy,
    sortOrder,
    handleHeaderClick,
    limit,
    offset,
  } = usePagination({ limit: 10, dependencies: [departments, $filter.pepAnalyticsDate] })
  const [pepList, setPepList] = useState([])

  async function loadPeps() {
    const response = await devPlanService.fetchAllPersonalEvolutionPlans(
      {
        departments,
        departmentsHierarchy: false,
        date: $filter.pepAnalyticsDate,
      },
      limit,
      offset,
      sortBy,
      sortOrder,
    )

    if (response.isSuccess && response.data?.data && response.data?.paging) {
      setPepList(response.data?.data)
      setPagingCount(response.data?.paging.count)
    }
  }

  const isLoading = useLoader(async () => {
    loadPeps()
  }, [departments, $filter.pepAnalyticsDate, limit, offset, sortBy, sortOrder])

  const headers = [
    { key: 'department', header: 'Подразделение', isSortable: true },
    { key: 'person', header: 'Сотрудник' },
    { key: 'author', header: 'Автор' },
    { key: 'createDate', header: 'Дата создания', isSortable: true },
    { key: 'startDate', header: 'Дата начала', isSortable: true },
    { key: 'endDate', header: 'Дата окончания', isSortable: true },
    { key: 'fileType', header: 'Формат хранения' },
  ]

  const rows = pepList.map((x: any) => ({
    id: x.id,
    department: x.department.name,
    person: x.person,
    author: x.author,
    createDate: formatDate(x.createDate),
    startDate: formatDate(x.startDate),
    endDate: formatDate(x.endDate),
    fileType: x.fileType,
    link: x.link,
  }))

  return (
    <WithLoaderWrapper isLoading={isLoading}>
      <DataTable headers={headers} rows={rows} isSortable={false}>
        {({
          rows,
          headers,
          getTableProps,
          getTableContainerProps,
          getHeaderProps,
          getRowProps,
        }) => {
          return (
            <TableContainer {...getTableContainerProps()}>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: any) => (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        isSortable={header.isSortable}
                        onClick={() => handleHeaderClick(header.key)}
                        isSortHeader={sortBy === header.key}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow {...getRowProps({ row })}>
                      {row.cells.map((cell, idx) => (
                        <TableCell key={row.id + cell.id}>{cell.value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }}
      </DataTable>
      <PaginationNav page={page} totalItems={totalItems} onChange={setPage} />
    </WithLoaderWrapper>
  )
}

export default observer(PepList)
