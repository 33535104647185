import React, { FC, useMemo } from 'react'
import { Bar } from 'react-chartjs-2'

import type { ChartOptions } from 'chart.js'
import 'chart.js/auto'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import { $catalog } from '@library/providers/StoreProvider'
import Title from '@library/ui/title/Title'
import { UIColorsObject } from '@library/ui/UIColors'

import { HCAnalyticsReasonMetrics } from '@services/models/dashboard/hcanalytics-reason-metrics'

type IProps = {
  data: HCAnalyticsReasonMetrics[]
  title: string
  className?: string
}

const options: ChartOptions<'bar'> = {
  indexAxis: 'y' as const,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
  },
}

const HCReasonsChart: FC<IProps> = ({ data, title, className }) => {
  const chartData = useMemo(() => {
    return {
      labels: data.map(
        (item) =>
          $catalog.healthCheckReason?.find((type) => type?.value === item.reason)?.shortName,
      ),
      datasets: [
        {
          data: data.map((item) => item.count),
          backgroundColor: UIColorsObject.blue,
          barThickness: 20,
          skipNull: true,
        },
      ],
    }
  }, [data])

  return (
    <>
      <Title size={'h4'} className={classNames('mb-10')}>
        {title}
      </Title>
      <div className={className}>
        <Bar data={chartData} options={options} />
      </div>
    </>
  )
}

export default observer(HCReasonsChart)
