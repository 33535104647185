import React, { FC, ReactNode } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { UserFeedbackViewer } from '@src/modules/userFeedback'

import ModalViewer from '@library/utils/modals/ModalViewer'

type IProps = {
  children: ReactNode
}

const PluginsProvider: FC<IProps> = ({ children }) => {
  return (
    <>
      {children}
      {/* plugins */}
      <ToastContainer />
      <ModalViewer />
      <UserFeedbackViewer />
    </>
  )
}

export default PluginsProvider
