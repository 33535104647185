/* tslint:disable */
/* eslint-disable */
/**
 * development-plan
 * План развития
 *
 * OpenAPI spec version: 1.0.23
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 *
 *
 * @export
 * @interface HealthCheckIndex
 */
export interface HealthCheckIndex {
  /**
   * код индекса
   *
   * @type {string}
   * @memberof HealthCheckIndex
   */
  code?: HealthCheckIndexCodeEnum

  /**
   * Значение индекса, в %, целое
   *
   * @type {number}
   * @memberof HealthCheckIndex
   */
  value?: number
}

/**
 * @export
 * @enum {string}
 */
export enum HealthCheckIndexCodeEnum {
  GeneralState = 'generalState',
  ManagerInteraction = 'managerInteraction',
  TeamInteraction = 'teamInteraction',
  Awareness = 'awareness',
  Evolution = 'evolution',
  CurrentTasks = 'currentTasks',
  WorkMode = 'workMode',
  Salary = 'salary',
  Communications = 'communications',
  Finance = 'finance',
  Performance = 'performance',
  Balance = 'balance',
}
