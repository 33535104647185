import React, { FC, useState } from 'react'

import { Button, ButtonSet, Column, Row } from '@carbon/react'
import { observer } from 'mobx-react-lite'

import { formErrors } from '@src/library/utils/constants'
import AddFilesTab from '@src/modules/healthcheck/ui/add-files-tab/AddFilesTab'
import HealthCheckFormTabs from '@src/modules/healthcheck/ui/form-tabs/HealthCheckFormTabs'
import HealthCheckFormFields from '@src/modules/healthcheck/ui/HealthCheckFormFields'

import { useRouterSearchParams } from '@helpers/router'

import { $user } from '@library/providers/StoreProvider'
import Title from '@library/ui/title/Title'
import { ModalBase, ModalBody, ModalFooter, ModalHeader } from '@library/utils/modals'
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store'
import { showErrorAlert } from '@library/utils/toast'

import { FileItem } from '@services/models/health-check'

type HealthCheckFormProps = {
  id: string | undefined
  _core: IModalItemCore
  forEdit?: boolean
}

const HealthCheckFormModal: FC<HealthCheckFormProps> = ({ id, _core, forEdit = false }) => {
  const [history, setHistory] = useState([])
  const [comments, setComments] = useState([])
  const [files, setFiles] = useState<FileItem[]>([])
  const searchParams = useRouterSearchParams()
  const onShowModal = () => id && searchParams.set('id', id)
  const onHideModal = () => id && searchParams.remove('id')

  const [isEdit, setIsEdit] = useState(forEdit)

  const isNew = !id

  function onSubmit(success: boolean) {
    if (success) {
      _core.hide()
    } else {
      showErrorAlert(formErrors.somethingWentWrong)
    }
  }

  function onFormDataLoad(data: any) {
    setHistory(data.historyList)
    setComments(data.commentsList)
  }

  const readOnly = !($user.hasPerm('PERM_HEALTHCHECK_FULL') && (isNew || isEdit))

  return (
    <ModalBase size={'xxl'} onShow={onShowModal} onHide={onHideModal}>
      <ModalHeader className="mb-20">
        <Title size="h2">
          {isEdit ? (
            <>Изменение Health Check {id}</>
          ) : isNew ? (
            <>Создание Health Check</>
          ) : (
            <>
              Просмотр Health Check {id}{' '}
              {!isEdit && $user.hasPerm('PERM_HEALTHCHECK_FULL') && (
                <small className="a" onClick={() => setIsEdit(true)}>
                  Редактировать
                </small>
              )}
            </>
          )}
        </Title>
      </ModalHeader>
      <ModalBody className="pb-20">
        <Row>
          <Column lg={8}>
            <HealthCheckFormFields
              id={id}
              formId="healthcheck-form-modal"
              onFormSubmit={onSubmit}
              readOnly={readOnly}
              onFormDataLoad={onFormDataLoad}
              files={files}
            />
          </Column>

          {id && (
            <Column lg={8}>
              <HealthCheckFormTabs healthCheckId={id!} history={history} comments={comments} />
            </Column>
          )}

          {!id && (
            <Column lg={8}>
              <AddFilesTab addFilesForNewHC={setFiles} />
            </Column>
          )}
        </Row>
      </ModalBody>
      {!readOnly && (
        <ModalFooter>
          <ButtonSet>
            <Button kind="tertiary" onClick={_core.hide}>
              Отмена
            </Button>
            <Button form="healthcheck-form-modal" type="submit">
              Сохранить
            </Button>
          </ButtonSet>
        </ModalFooter>
      )}
    </ModalBase>
  )
}

export default observer(HealthCheckFormModal)
