import React, { FC, useState } from 'react'

import {
  DataTable,
  PaginationNav,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react'
import { observer } from 'mobx-react-lite'

import { useLoader, usePagination } from '@src/library/utils/hooks'
import { indexEnumMetaActive } from '@src/modules/healthcheck'

import { formatDate, formatQuarter } from '@helpers/date'

import { $catalog } from '@library/providers/StoreProvider'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

import { devPlanService } from '@services'
import { HCFilter } from '@services/models/dashboard/hcfilter'
import { HealthCheck, HealthCheckIndex } from '@services/models/health-check'

interface IProps {
  filters: HCFilter
  className?: string
}

type SortByType = Parameters<typeof devPlanService.getHCFiltred>[3]

const HCAnalyticTable: FC<IProps> = ({ filters, className }) => {
  const [dataList, setDataList] = useState([])
  const pagination = usePagination<SortByType>()

  const loadData = async () => {
    if (!filters?.periods?.length) {
      return
    }

    const response = await devPlanService.getHCFiltred(
      filters,
      pagination.limit,
      pagination.offset,
      pagination.sortBy,
      pagination.sortOrder,
    )

    if (response.isSuccess && response.data?.healthCheck) {
      setDataList(response.data.healthCheck)
      pagination.setPagingCount(response.data.paging?.count ?? 0)
    } else {
      setDataList([])
      pagination.setPagingCount(0)
    }
  }

  const isLoading = useLoader(async () => {
    await loadData()
  }, [filters, pagination.page, pagination.sortBy, pagination.sortOrder])

  let indexHeaders = Object.keys(indexEnumMetaActive).map((key) => ({
    key,
    header: indexEnumMetaActive[key].title,
    tablePosition: indexEnumMetaActive[key].tablePosition,
    isSortable: false,
  }))

  const headers: Array<{
    key: string
    header: string
    isSortable?: boolean
  }> = [
    { key: 'code', header: 'Код', isSortable: true },
    { key: 'departmentName', header: 'Подразделение', isSortable: true },
    { key: 'startDate', header: 'За период', isSortable: true },
    { key: 'createDate', header: 'Дата создания', isSortable: true },
    { key: 'author', header: 'Автор', isSortable: true },
    { key: 'reason', header: 'Причина проведения', isSortable: true },
  ].concat(indexHeaders)

  const rows = dataList.map((item: HealthCheck, index) => {
    const indexes: { [char: string]: string } = {}

    item.indexList?.forEach((idx: HealthCheckIndex) => {
      if (idx.value) {
        indexes[idx.code!] = `${idx.value}`
      }
    })

    return {
      id: item?.id ?? `${index}`,
      code: item?.code,
      departmentName: item?.departmentName,
      startDate: formatQuarter(item?.startDate),
      createDate: formatDate(item?.createDate),
      author: item?.author,
      reason: $catalog.healthCheckReason?.find((type) => type?.value === item?.reason)?.shortName,
      ...indexes,
    }
  })

  return (
    <WithLoaderWrapper isLoading={isLoading} mode={'update'} className={className}>
      <DataTable headers={headers} rows={rows} isSortable={true} size="lg">
        {({
          rows,
          headers,
          getTableProps,
          getTableContainerProps,
          getHeaderProps,
          getRowProps,
        }) => {
          return (
            <TableContainer {...getTableContainerProps()}>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: any) => (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        isSortHeader={pagination.sortBy === header.key}
                        isSortable={header?.isSortable}
                        sortDirection={pagination.sortOrder?.toUpperCase()}
                        onClick={() => pagination.handleHeaderClick(header.key)}
                        key={header?.key}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.length > 0 ? (
                    rows.map((row) => (
                      <TableRow {...getRowProps({ row })}>
                        {row.cells.map((cell) => {
                          const cellKey = [row.id, cell.id].join('_')

                          return <TableCell key={cellKey}>{cell.value}</TableCell>
                        })}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="mt-20">
                      <TableCell colSpan={headers.length}>Нет записей</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }}
      </DataTable>
      <PaginationNav
        page={pagination.page}
        totalItems={pagination.totalItems}
        onChange={pagination.setPage}
        itemsShown={pagination.itemsShown}
      />
    </WithLoaderWrapper>
  )
}

export default observer(HCAnalyticTable)
