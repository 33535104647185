import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { useLoader, usePagination } from '@src/library/utils/hooks'
import {
  useTaskAuthorFilter,
  useTaskPeriodFilter,
  useTaskStatusFilter,
} from '@src/modules/taskList'
import TasksList from '@src/modules/taskList/ui/TasksList'

import { formatDate } from '@helpers/date'

import { $filter, $user } from '@library/providers/StoreProvider'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

import { humanTaskService } from '@services'
import { ITaskStatuses } from '@services/HumanTaskService'

const TasksPage = () => {
  const { selectedStatuses } = useTaskStatusFilter()
  const { startDate, endDate } = useTaskPeriodFilter()

  const { author } = useTaskAuthorFilter()
  const [searchValue, setSearchValue] = useState('')

  const [taskList, setTaskList] = useState<any>([])

  const paginationOptions = usePagination({
    defaultSortBy: 'createDate',
    defaultSortOrder: 'desc',
    dependencies: [selectedStatuses, startDate, endDate, $filter.selectedCodes, searchValue],
  })

  const { limit, offset, sortBy, sortOrder, setPagingCount } = paginationOptions

  async function loadData() {
    const selectedDepartments =
      $user.hasPerm('PERM_MYDEPARTMENTS_MANAGER_READ') && $filter.selectedCodes.length === 0
        ? undefined
        : $filter.selectedCodes

    const startDateParam = startDate ? formatDate(startDate, { format: 'isoDate' }) : undefined
    const endDateParam = endDate ? formatDate(endDate, { format: 'isoDate' }) : undefined
    const manager =
      $user.hasPerm('PERM_TASK_MANAGER') && !selectedDepartments ? $user.manager : undefined

    const response = await humanTaskService.fetchTasks({
      departments: selectedDepartments,
      startDate: startDateParam,
      endDate: endDateParam,
      taskStatuses: selectedStatuses.map((x: any) => x.value) as ITaskStatuses[],
      author,
      manager,
      searchValue,
      limit,
      offset,
      sortOrder,
      sortBy,
    })

    if (response.isSuccess) {
      setTaskList(response.data?.data ?? [])
      setPagingCount(response.data?.paging?.count ?? 0)
    }
  }

  const isLoading = useLoader(async () => {
    await loadData()
  })

  useEffect(() => {
    if (!isLoading) {
      loadData()
    }
  }, [
    selectedStatuses,
    startDate,
    endDate,
    $filter.selectedCodes,
    author,
    searchValue,
    offset,
    sortOrder,
    sortBy,
  ])

  function shouldShowProfileLink(cell: any) {
    return true
  }

  return (
    <WithLoaderWrapper isLoading={isLoading}>
      <TasksList
        loadData={loadData}
        taskList={taskList}
        showDepartment={true}
        shouldShowProfileLink={shouldShowProfileLink}
        shouldShowAddTaskButton={$user.hasPerm('PERM_TASK_ADD')}
        paginationOptions={paginationOptions}
        onSearchChange={(v: string) => {
          setSearchValue(v)
        }}
      />
    </WithLoaderWrapper>
  )
}

export default observer(TasksPage)
