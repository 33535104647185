import React, { FC, useState } from 'react'

import { Loading } from '@carbon/react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import { downloadBy } from '@helpers/other'

import FileTypeIcon from '@library/ui/fileTypeIcon/FileTypeIcon'

import styles from './DownloadFiles.module.scss'

type FileItem = {
  id?: string
  fileName?: string
}

type FileContent = {
  url?: string
  file?: Blob
  fileName?: string
  fileType?: string
}

type IProps = {
  files: FileItem[]
  callback: (file: FileItem) => Promise<FileContent | undefined>
  className?: string
}

const DownloadFiles: FC<IProps> = ({ files = [], callback, className }) => {
  const [inProcess, setInProcess] = useState(new Set())

  const handleDownload = async (file: FileItem, key: string) => {
    setInProcess((x) => new Set(x).add(key))

    const fileContent = await callback(file)

    if (fileContent) {
      downloadBy(
        {
          url: fileContent.url,
          base64Data: { file: fileContent.file!, fileType: fileContent.fileType! },
        },
        { fileName: file.fileName, mode: 'newTab' },
      )
    }

    setInProcess((x) => {
      const newSet = new Set(x)
      newSet.delete(key)

      return newSet
    })
  }

  return (
    <div className={className}>
      {files.map((file) => {
        const key = ['download-file', file.id, file.fileName].join('-')

        return (
          <div
            key={key}
            className={classNames(styles.file, inProcess.has(key) && styles.fileDisabled)}
            onClick={() => handleDownload(file, key)}
          >
            <div className={styles.icon}>
              {inProcess.has(key) ? (
                <Loading small={true} withOverlay={false} />
              ) : (
                <FileTypeIcon fileName={file.fileName} />
              )}
            </div>
            <span>{file.fileName}</span>
          </div>
        )
      })}
    </div>
  )
}

export default observer(DownloadFiles)
