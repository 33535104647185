import React, { FC, useEffect, useMemo, useState } from 'react'

import {
  DataTable,
  /* @ts-ignore */
  MultiSelect,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableExpandHeader,
  TableExpandRow,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'

import { useLoader } from '@src/library/utils/hooks'
import { indexEnumMeta } from '@src/modules/healthcheck'

import { formatQuarter } from '@helpers/date'

import { UIColorsObject } from '@library/ui/UIColors'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

import { dashboardService } from '@services'
import { HeatMapDepartment } from '@services/models/dashboard'
import { HCFilter } from '@services/models/dashboard/hcfilter'
import { HeatMap } from '@services/models/dashboard/heat-map'

import styles from './HCHeatMap.module.scss'

interface IProps {
  filters: HCFilter
  className?: string
}

const rangesTypes = [
  {
    valueFrom: 75,
    valueTo: 100,
    color: UIColorsObject.green,
  },
  {
    valueFrom: 50,
    valueTo: 74,
    color: UIColorsObject.blue,
  },
  {
    valueFrom: 25,
    valueTo: 49,
    color: UIColorsObject.yellow,
  },
  {
    valueFrom: 1,
    valueTo: 24,
    color: UIColorsObject.orange,
  },
]

const getMaxDepth = (departments: any[], currentDepth: number = 0): number => {
  if (_.isEmpty(departments)) {
    return currentDepth
  }

  return (
    _.max(
      departments.map((department) => getMaxDepth(department.childrenDeparments, currentDepth + 1)),
    ) || currentDepth
  )
}

const HCHeatMap: FC<IProps> = ({ filters, className }) => {
  const [data, setData] = useState<HeatMap[]>([])
  const [ranges, setRanges] = useState(rangesTypes)
  const [rows, setRows] = useState<any[]>([])
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({})
  const periods = filters?.periods

  const isLoading = useLoader(async () => {
    if (periods?.length) {
      const response = await dashboardService.getHCHeatMap({
        ...filters,
        indexValues: ranges?.map((item) => ({
          valueFrom: item?.valueFrom,
          valueTo: item?.valueTo,
        })),
      })

      if (response.isSuccess && response.data) {
        setData(response?.data)
      }
    }
  }, [filters, ranges])

  const generateRows = () => {
    const newRows: any[] = []

    data?.forEach((dataItem) => {
      const periods = dataItem.hcindex?.averageValues?.reduce(
        (acc: { [key: string]: any }, average: any) => {
          const key = `${dayjs(average?.startDate).format('YYYY-MM-DD')}-${dayjs(
            average?.endDate,
          ).format('YYYY-MM-DD')}`
          acc[key] = average.value

          return acc
        },
        {},
      )

      const rowId = `${dataItem.hcindex?.code}`

      newRows.push({
        id: rowId,
        code: indexEnumMeta[rowId]?.title,
        ...periods,
        isAverageValues: true,
      })

      generateRowRecursively(dataItem.hcindex?.departments!, 1, newRows, rowId)
    })

    return newRows
  }

  useEffect(() => {
    const newRows = generateRows()
    setRows(newRows)
  }, [data, expandedRows])

  useEffect(() => {
    const newRows = generateRows()
    const initialExpandedRows = newRows.reduce((acc, item) => {
      acc[item.id] = true

      return acc
    }, {})

    setExpandedRows(initialExpandedRows)
  }, [data])

  const headers = useMemo(() => {
    const baseHeaders = [{ key: 'code', header: 'Аналитика' }]
    const maxDepth = getMaxDepth(_.get(data, '[0].hcindex.departments', []))

    const depsHeaders = _.times(maxDepth, (i: number) => ({
      key: `department${i + 1}`,
      header: `Подразделение (${i + 1})`,
    }))

    const periodHeaders =
      periods?.map((period) => ({
        key: `${period.startDate}-${period.endDate}`,
        header: formatQuarter(period.startDate),
      })) || []

    return [...baseHeaders, ...depsHeaders, ...periodHeaders]
  }, [periods, data])

  const generateRowRecursively = (
    departments: HeatMapDepartment[],
    level: number,
    rows: any[],
    parentId?: string,
  ): void => {
    departments.forEach((department) => {
      const rowId = parentId ? `${parentId}-${department.name}` : department.name
      const values =
        !expandedRows[rowId!] && department?.averageValues
          ? department?.averageValues
          : department?.indexValues
      const isAverageValues = !!(!expandedRows[rowId!] && department?.averageValues)
      const periods = values?.reduce((acc: { [key: string]: any }, item) => {
        const key = `${dayjs(item.startDate).format('YYYY-MM-DD')}-${dayjs(item.endDate).format(
          'YYYY-MM-DD',
        )}`
        acc[key] = item.value

        return acc
      }, {})

      const row = {
        id: rowId,
        [`department${level}`]: department.name,
        ...periods,
        isAverageValues,
      }

      rows.push(row)

      if (department.childrenDeparments?.length) {
        generateRowRecursively(department.childrenDeparments, level + 1, rows, rowId)
      }
    })
  }

  const handleExpand = (rowId: any) => {
    if (expandedRows[rowId]) {
      const newRowStates = Object.keys(expandedRows).reduce(
        (newStates: { [key: string]: boolean }, key) => {
          if (key.startsWith(`${rowId}-`) || key === rowId) {
            newStates[key] = false
          } else {
            newStates[key] = expandedRows[key]
          }

          return newStates
        },
        {},
      )

      setExpandedRows(newRowStates)
    } else {
      setExpandedRows({ ...expandedRows, [rowId]: true })
    }
  }

  const getIsAverageValue = (id: string) => {
    return rows?.find((item) => item?.id === id)?.isAverageValues
  }

  const getColorForValue = (value: number, text?: boolean): string => {
    for (const range of rangesTypes) {
      if (value >= range.valueFrom && value <= range.valueTo) {
        return range.color
      }
    }

    return text ? '#000' : '#fff'
  }

  const shouldShowRow = (rowId: string) => {
    const parentIdParts = rowId.split('-')
    const parentExists = parentIdParts.length > 1

    if (!parentExists) {
      return true
    }

    parentIdParts.pop()
    const immediateParentId = parentIdParts.join('-')

    return !!expandedRows[immediateParentId]
  }

  const hasChildren = (rowId: string) => {
    return rows.some((otherRow) => {
      return otherRow.id.startsWith(`${rowId}-`)
    })
  }

  if (!periods?.length) {
    return <></>
  }

  return (
    <div className={className}>
      <MultiSelect
        id="ranges"
        label={!ranges?.length || ranges?.length === 4 ? 'Все' : ''}
        onChange={(e: any) => setRanges(e.selectedItems)}
        initialSelectedItems={ranges}
        items={rangesTypes}
        itemToElement={(option: any) => (
          <div style={{ backgroundColor: option?.color }}>
            <p style={{ color: option.color }}>Цвет</p>
          </div>
        )}
        size="sm"
        className={classNames(styles.filterableSelect, 'multiselect-filter--without-tag')}
        titleText="Цвет"
      />
      <WithLoaderWrapper isLoading={isLoading} className={classNames(styles.heatTable, 'mt-10')}>
        <DataTable rows={rows} headers={headers} size="sm">
          {({ rows, headers, getHeaderProps, getTableProps, getExpandedRowProps }) => (
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    <TableExpandHeader aria-label="expand row" />
                    {headers.map((header) => (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        onClick={() => {}}
                        key={header.key}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row: any, index: number) => {
                    const rowKey = ['hc_map', row.id, index].join('_')
                    const isVisible = shouldShowRow(row.id)
                    const canExpand = hasChildren(row.id)
                    const isAverage = getIsAverageValue(row.id)

                    return (
                      <TableExpandRow
                        {...getExpandedRowProps({ row })}
                        key={rowKey}
                        onExpand={canExpand ? () => handleExpand(row.id) : () => {}}
                        aria-label={canExpand ? 'expand row' : 'row'}
                        isExpanded={canExpand ? Boolean(expandedRows[row.id]) : false}
                        className={classNames(
                          !canExpand && styles.nonExpandable,
                          !expandedRows[row.id] && !isVisible && `${styles.none}`,
                        )}
                      >
                        {row.cells.map((cell: any) => {
                          const cellKey = ['hc_map', row.id, cell.id].join('_')

                          return (
                            <TableCell
                              key={cellKey}
                              style={{
                                backgroundColor: isAverage ? '#fff' : getColorForValue(cell.value),
                                color: isAverage ? getColorForValue(cell.value, true) : '#000',
                                fontWeight: typeof cell.value === 'number' ? '700' : '400',
                              }}
                            >
                              {cell.value}
                            </TableCell>
                          )
                        })}
                      </TableExpandRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>
      </WithLoaderWrapper>
    </div>
  )
}

export default observer(HCHeatMap)
