import React, { FC, Fragment } from 'react'
import { NavLink } from 'react-router-dom'

import Dropdown from '@library/ui/dropdown/Dropdown'

import { MenuItem } from '../model/config/AppNavigation.config'
import styles from './AppNavigation.module.scss'

type IProps = {
  menuItems: MenuItem[]
  onClose?: Function
}

const HeaderMenu: FC<IProps> = ({ menuItems, onClose }) => {
  return (
    <>
      {menuItems.map((item, index) => {
        const key = [index, item.text].join('_')

        if (item.condition && !item.condition()) {
          return <Fragment key={key} />
        }

        if (item.component) {
          return <Fragment key={key}>{item.component}</Fragment>
        }

        if (Array.isArray(item.subItems)) {
          return (
            <Dropdown
              key={key}
              text={item.text}
              secondaryText={item.secondaryText}
              className={styles.subMenu}
              buttonClassName={styles.navLink}
              popupClassName={styles.subMenuPopup}
              align={item.subItemsAlign}
            >
              {({ onClose: _onClose }) => (
                <HeaderMenu
                  menuItems={item.subItems!}
                  onClose={() => {
                    _onClose && _onClose()
                    onClose && onClose()
                  }}
                />
              )}
            </Dropdown>
          )
        } else {
          const Tag = item.to ? NavLink : 'div'

          return (
            <Tag
              key={key}
              to={item.to!}
              onClick={() => {
                item.onClick && item.onClick()
                onClose && onClose()
              }}
              className={styles.navLink}
            >
              <span>
                {item.text}
                {item.secondaryText}
              </span>
            </Tag>
          )
        }
      })}
    </>
  )
}

export default HeaderMenu
