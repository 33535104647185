import React, { FC, ReactNode, useEffect, useState } from 'react'

import classNames from 'classnames'
import classnames from 'classnames'

import styles from './Accordion.module.scss'

interface IProps {
  items: Array<{ title: ReactNode | string; content: ReactNode }>
  allowMultiple?: boolean
  initActiveIndex?: number
  className?: string
  variant?: 'default'
}

const Accordion: FC<IProps> = ({
  items,
  allowMultiple,
  initActiveIndex,
  variant = 'default',
  className,
}) => {
  const [activeIndexes, setActiveIndexes] = useState<number[]>([])

  const toggleItem = (index: number) => {
    if (activeIndexes.includes(index)) {
      setActiveIndexes(activeIndexes.filter((i) => i !== index))
    } else {
      if (allowMultiple) {
        setActiveIndexes([...activeIndexes, index])
      } else {
        setActiveIndexes([index])
      }
    }
  }

  useEffect(() => {
    if (
      items.length &&
      typeof initActiveIndex !== 'undefined' &&
      !activeIndexes.includes(initActiveIndex)
    ) {
      toggleItem(0)
    }
  }, [items, initActiveIndex])

  return (
    <div className={classNames(styles.accordion, styles[variant], className)}>
      {items.map((item, index) => {
        const extraTitleStyle = typeof item.title === 'string' ? styles.titleBold : ''

        return (
          <div
            key={index}
            className={classNames(styles.item, activeIndexes.includes(index) && styles.itemActive)}
          >
            <div
              className={classnames(styles.title, extraTitleStyle)}
              onClick={() => toggleItem(index)}
            >
              {item.title}
            </div>
            {activeIndexes.includes(index) && <div className={styles.content}>{item.content}</div>}
          </div>
        )
      })}
    </div>
  )
}

export default Accordion
