import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import queryString from 'query-string'

import { $user } from '@library/providers/StoreProvider'

import { getConfig } from '@services/config'

//TODO ensure populated app config is provided
import { tokenService } from '.'

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer(params: any) {
    return queryString.stringify(params, { arrayFormat: 'none' })
  },
})

instance.interceptors.request.use(async (config) => {
  if (!tokenService.token) {
    return config
  }

  if (config?.headers?.['withAuthorization']) {
    delete config.headers['withAuthorization']
    config.headers['Authorization'] = 'Bearer ' + tokenService.token
  }

  return config
})

instance.interceptors.request.use(async (config) => {
  const appConfig = getConfig()
  const roleDependentServices = [
    appConfig.catalogServiceUrl,
    appConfig.dashboardServiceUrl,
    appConfig.devPlanServiceUrl,
    appConfig.taskServiceUrl,
    appConfig.personServiceUrl,
  ]

  const shouldApply = roleDependentServices.some((prefix) => config.url?.startsWith(prefix))
  const roleGroup = $user.roleGroup

  if (shouldApply && roleGroup) {
    config.params = { ...config.params, roleGroup }
  }

  return config
})

type ApiRequestOptions =
  | {
      url: string
      method: Extract<Method, 'GET' | 'HEAD' | 'DELETE' | 'OPTIONS'>
      data?: Record<string, any>
      params?: never
      withAuth?: boolean
      axiosParams?: Omit<AxiosRequestConfig, 'url' | 'method' | 'data' | 'params' | 'headers'>
      cacheDuration?: number
      deDuplication?: boolean
    }
  | {
      url: string
      method: Extract<Method, 'POST' | 'PUT' | 'PATCH' | 'PURGE' | 'LINK' | 'UNLINK'>
      data?: any
      params?: Record<string, any>
      withAuth?: boolean
      axiosParams?: Omit<AxiosRequestConfig, 'url' | 'method' | 'data' | 'params' | 'headers'>
      cacheDuration?: number
      deDuplication?: boolean
    }

type SuccessResponse<T> = { isSuccess: true } & AxiosResponse<T>
type ErrorResponse = { isSuccess: false } & AxiosError

const cache = new Map<string, { response: SuccessResponse<any> | ErrorResponse; expiry: number }>()
const pendingRequests = new Map<string, Promise<SuccessResponse<any> | ErrorResponse>>()

export const apiRequest = async <T = any>({
  url,
  method = 'GET',
  data,
  params,
  withAuth = true,
  axiosParams = {},
  cacheDuration = 0, // в ms (1000 = 1s)
  deDuplication = true,
}: ApiRequestOptions): Promise<SuccessResponse<T> | ErrorResponse> => {
  const dataOrParams = ['GET', 'HEAD', 'DELETE', 'OPTIONS'].includes(method) ? 'params' : 'data'
  const cacheKey = `${method}:${url}:${JSON.stringify(params)}:${JSON.stringify(data)}`

  const now = Date.now()

  for (const [key, { expiry }] of cache.entries()) {
    if (expiry <= now) {
      cache.delete(key)
    }
  }

  if (cacheDuration > 0) {
    const cached = cache.get(cacheKey)

    if (cached && cached.expiry > now) {
      return cached.response as SuccessResponse<T> | ErrorResponse
    }
  }

  if (deDuplication && pendingRequests.has(cacheKey)) {
    return pendingRequests.get(cacheKey) as Promise<SuccessResponse<T> | ErrorResponse>
  }

  if (withAuth) {
    _.set(axiosParams, 'headers.withAuthorization', true)

    if (tokenService.shouldRefresh()) {
      await tokenService.refreshToken()
    }
  }

  const requestPromise: Promise<SuccessResponse<T> | ErrorResponse> = (async () => {
    let response
    let error

    try {
      response = await instance({
        url,
        method,
        params: filterSortParams(params),
        [dataOrParams]: filterSortParams(data),
        ...axiosParams,
      })
    } catch (e) {
      error = e as AxiosError
    }

    if (error) {
      return { isSuccess: false, ...error }
    }

    // @ts-ignore
    const successResponse: SuccessResponse<T> = { isSuccess: true, ...response }

    if (cacheDuration > 0) {
      cache.set(cacheKey, { response: successResponse, expiry: now + cacheDuration })
    }

    return successResponse
  })()

  if (deDuplication) {
    pendingRequests.set(cacheKey, requestPromise)
  }

  const result = await requestPromise

  if (deDuplication) {
    pendingRequests.delete(cacheKey)
  }

  return result
}

function filterSortParams(params: Record<string, any> | undefined) {
  if (params?.sortOrder && !params?.sortBy) {
    return _.omit(params, 'sortOrder')
  }

  return params
}
