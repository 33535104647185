import React, { FC } from 'react'
import { Line } from 'react-chartjs-2'

import 'chart.js/auto'
import dayjs, { Dayjs } from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { observer } from 'mobx-react-lite'

import { TaskAnalyticsStatusCumulative } from '@services/models/dashboard'

dayjs.extend(isoWeek)

type Props = {
  data: TaskAnalyticsStatusCumulative[]
  startDate: Dayjs
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right' as const,
    },
    title: {
      display: true,
      text: 'Задачи по статусам',
      position: 'top' as const,
      color: '#000000',
      padding: 10,
      font: { size: 16 },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Недели',
      },
      beginAtZero: true,
      ticks: {
        autoSkip: false,
      },
    },
    y: {
      title: {
        display: true,
        text: 'Количество задач',
      },
      stacked: true,
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
  },
}

const CumulativeChart: FC<Props> = ({ data, startDate }) => {
  const numWeeks = data[0]?.values?.length || 0
  const labels = Array.from({ length: numWeeks - 1 }, (_, i) =>
    startDate.startOf('isoWeek').add(i, 'week').format('DD.MM.YYYY'),
  )

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Сделать',
        data: data?.find((d) => d.status === 'ToDo')?.values,
        backgroundColor: 'rgba(255, 205, 28, 0.8)',
        borderColor: 'rgba(255, 205, 28, 1)',
        fill: true,
      },
      {
        label: 'В работе',
        data: data?.find((d) => d.status === 'InProgress')?.values,
        backgroundColor: 'rgba(33, 112, 250, 0.8)',
        borderColor: 'rgba(33, 112, 250, 1)',
        fill: true,
      },
      {
        label: 'Готово',
        data: data?.find((d) => d.status === 'Done')?.values,
        backgroundColor: 'rgba(89, 229, 0, 0.8)',
        borderColor: 'rgba(89, 229, 0, 1)',
        fill: true,
      },
    ],
  }

  return <Line data={chartData} options={options} height={400} />
}

export default observer(CumulativeChart)
