import React, { FC, Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Button, Tag } from '@carbon/react'
import classNames from 'classnames'

import PersonPhoto from '@library/ui/personPhoto/PersonPhoto'
import Title from '@library/ui/title/Title'
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store'

import { PersonCard as PersonCardType } from '@services/models/person'

import { useBreadcrumb } from '../../model/PersonHierarchyModal.hooks'
import { ExtendedPersonsObj } from '../../model/PersonHierarchyModal.types'
import styles from './PersonCard.module.scss'

type IProps = {
  data: PersonCardType
  onChangePerson: (nickName: string) => void
  extendedPersonsObj?: ExtendedPersonsObj
  className?: string
  _core: IModalItemCore
}

const PersonCard: FC<IProps> = ({ data, extendedPersonsObj, onChangePerson, className, _core }) => {
  const nav = useNavigate()

  const currentPerson = extendedPersonsObj?.[data?.nickName!]
  const breadcrumb = useBreadcrumb(currentPerson, extendedPersonsObj).slice(0, -1)

  return (
    <div className={classNames(styles.personCard, className)}>
      <div className={styles.userData}>
        <PersonPhoto src={data.photo} className={styles.photo} />
        <div>
          <div className={styles.name}>
            <Title size={'h3'}>{data.name}</Title>
            <Tag type="high-contrast">{data.nickName}</Tag>
          </div>
          <div className={styles.property}>
            <label>{data.jobPosition}</label>
          </div>
          <div className={styles.property}>
            <label>Подразделение:</label>
            {/*{data.department!.name}*/}
            <Link
              to={`/company-structure/?search=${encodeURIComponent(data.department!.name!)}`}
              onClick={() => _core.hide()}
            >
              {data.department!.name}
            </Link>
          </div>
          {data.functionalManager && (
            <div className={styles.property}>
              <label>Фактический руководитель:</label>
              <span className="a" onClick={() => onChangePerson(data.functionalManager!)}>
                {data.functionalManager}
              </span>
            </div>
          )}
          {breadcrumb.length > 1 ? (
            <div className={styles.property}>
              <label>Структурные руководители:</label>
              <span className={styles.breadcrumb}>
                {breadcrumb.map((item, index) => {
                  const isLast = index === breadcrumb.length - 1

                  return (
                    <Fragment key={item.nickName!}>
                      <span
                        className={classNames(
                          styles.breadcrumbItem,
                          isLast && styles.breadcrumbItemLast,
                        )}
                        onClick={() => onChangePerson(item.nickName!)}
                      >
                        {item.nickName}
                      </span>
                      {!isLast && <span className={styles.breadcrumbDelimiter}>/</span>}
                    </Fragment>
                  )
                })}
              </span>
            </div>
          ) : (
            data.manager && (
              <div className={styles.property}>
                <label>Структурный руководитель:</label>
                <span className="a" onClick={() => onChangePerson(data.manager!)}>
                  {data.manager}
                </span>
              </div>
            )
          )}
          <div className={classNames(styles.property, 'mt-10')}>
            <Button
              as={'a'}
              href={'/user-profile/' + data.nickName}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.preventDefault()
                nav('/user-profile/' + data.nickName)
                _core.hide()
              }}
              kind={'tertiary'}
              size={'sm'}
              className={styles.button}
            >
              Открыть профиль
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonCard
