import React, { useMemo, useState } from 'react'

import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react'

import { useLoader } from '@src/library/utils/hooks'

import { formatDate } from '@helpers/date'

import LayoutLkPageWrapper from '@library/layouts/LayoutLkPageWrapper'
import { useStore } from '@library/providers/StoreProvider'

import { devPlanService } from '@services'

const SkillReviewsPage = ({ person: _person }: { person?: any }) => {
  const { $cabinet, $user } = useStore()
  const [skillReviewList, setSkillReviewList] = useState([])

  const isLoading = useLoader(async () => {
    const startDate = $user.hasPerm('PERM_SKILL_REVIEW_MANAGER_READ')
      ? $cabinet.data?.startDate
      : undefined

    const response = await devPlanService.fetchSkillReview({
      person: $cabinet.selectedLogin,
      startDate,
    })

    if (response.isSuccess) {
      setSkillReviewList(response.data?.data || [])
    }
  })

  const sorted = useMemo(() => {
    return _.orderBy(skillReviewList, 'startDate', 'desc')
  }, [skillReviewList])

  const headers = [
    { key: 'campaignName', header: 'Опрос' },
    { key: 'period', header: 'Период' },
    { key: 'publishRevieweeDate', header: 'Дата публикации оценки' },
    { key: 'hardSkillsGrade', header: 'HardSkills' },
    { key: 'softSkillsGrade', header: 'SoftSkills' },
  ]

  const rows = sorted.map((x: any, index) => ({
    id: (index + 1).toString(),
    period: `${formatDate(x.startDate)} - ${formatDate(x.endDate)}`,
    publishRevieweeDate: formatDate(x.publishRevieweeDate),
    campaignName: (
      <a href={x.skillUrl} title="переход в AIDAY" target="_blank" rel="noreferrer">
        {x.campaignName}
      </a>
    ),
    hardSkillsGrade: x.hardSkillsGrade,
    softSkillsGrade: x.softSkillsGrade,
  }))

  return (
    <LayoutLkPageWrapper isLoading={false}>
      <DataTable headers={headers} rows={rows} isSortable={false}>
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => {
          return (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader
                      {...getHeaderProps({ header })}
                      onClick={() => {}}
                      style={{ width: `${100 / headers.length}%` }}
                    >
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow {...getRowProps({ row })} onClick={() => {}}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )
        }}
      </DataTable>
    </LayoutLkPageWrapper>
  )
}

export default SkillReviewsPage
