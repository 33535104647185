import { makeAutoObservable } from 'mobx'

import { CabinetStore } from '@src/modules/cabinet'
import { CatalogStore } from '@src/modules/catalog'
import { AppFilterStore } from '@src/modules/globalFilter'
import { UserStore } from '@src/modules/user'
import { jobProfilesStore } from '@src/pages/reference/modules/job-profiles'

import GlobalStore from '@library/utils/global/globalStore'
import LoaderStore from '@library/utils/loader/loaderStore'
import ModalViewerStore from '@library/utils/modals/ModalViewer.store'

type StoreSchema = {
  jobProfiles: typeof jobProfilesStore
}

export class RootStore {
  stores: Record<string, any> = {}
  constructor() {
    makeAutoObservable(this)
    this.$global = new GlobalStore(this)
    this.$loader = new LoaderStore(this)
    this.$modal = ModalViewerStore
    this.$user = new UserStore(this)
    this.$catalog = new CatalogStore(this)
    this.$cabinet = new CabinetStore(this)
    this.$filter = new AppFilterStore(this)
    this.$jobProfiles = jobProfilesStore
  }

  addStore(key: string, store: any) {
    if (!this.stores[key]) {
      this.stores[key] = store
    }
  }

  removeStore(key: string) {
    if (this.stores[key]) {
      delete this.stores[key]
    }
  }

  getStore(key: string) {
    return this.stores[key]
  }

  $global
  $loader
  $modal
  $user
  $catalog
  $cabinet
  $filter
  $jobProfiles
}

export const $root = new RootStore()
export const $global = $root.$global
export const $loader = $root.$loader
export const $modal = $root.$modal
export const $user = $root.$user
export const $catalog = $root.$catalog
export const $cabinet = $root.$cabinet
export const $filter = $root.$filter
