import { useState } from 'react'

import { useLoader } from '@src/library/utils/hooks'

import { $user } from '@library/providers/StoreProvider'

import { personService } from '@services'
import { PersonCardForList } from '@services/models/person'

type PersonsFilterItem = {
  key: string
  text: string
  value: string
}

export const usePersonsFilter = () => {
  const [persons, setPersons] = useState<PersonCardForList[]>([])
  const [mappedPersons, setMappedPersons] = useState<PersonsFilterItem[]>([])

  const isPersonsLoading = useLoader(async () => {
    const manager = $user.hasPerm('PERM_TASK_MANAGER') ? $user.nickname : undefined

    const response = await personService.fetch(manager, undefined, manager)

    if (response.isSuccess && response.data?.data) {
      const data = _.orderBy(response.data?.data, 'nickName', 'asc')
      setPersons(data)

      const mapped = data.map((user, index) => ({
        key: [index, user.nickName].join('_'),
        text: user.nickName!,
        value: user.nickName!,
      }))
      setMappedPersons(mapped)
    }
  }, [])

  return {
    persons,
    mappedPersons,
    isPersonsLoading,
  }
}
