import * as React from 'react'
import { FC, ReactNode, useEffect, useRef, useState } from 'react'

import { Loading } from '@carbon/react'
import classNames from 'classnames'

import { useFirstRender } from '@src/library/utils/hooks'

import DefaultSpinner from '@library/ui/spinner/DefaultSpinner'

import styles from './WithLoaderWrapper.module.scss'

interface Props {
  isLoading?: boolean
  children?: ReactNode
  size?: 'regular' | 'small'
  align?: 'top' | 'center' | 'bottom'
  className?: string
  mode?: 'default' | 'update'
}

const alignPropMapping = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
}

const WithLoaderWrapper: FC<Props> = ({
  isLoading = false,
  children,
  size = 'regular',
  align = 'center',
  className,
  mode = 'default',
}) => {
  const isFirstRender = useFirstRender()
  const initialize = useRef<boolean | null>(null)
  const childrenRef = useRef<HTMLDivElement>(null)

  const [height, setHeight] = useState<number>()
  const alignItems = alignPropMapping[align]

  useEffect(() => {
    childrenRef.current && setHeight(childrenRef.current.clientHeight)

    if (!isFirstRender) {
      initialize.current = isLoading
    }
  }, [isLoading])

  function getLoader(asOverlay = false) {
    return (
      <div
        className={classNames(styles.loader, asOverlay && styles.loaderOverlay)}
        style={{ height, alignItems }}
      >
        {size === 'small' ? <Loading small={true} withOverlay={false} /> : <DefaultSpinner />}
      </div>
    )
  }

  return (
    <>
      {!isLoading ? (
        <div ref={childrenRef} className={classNames(styles.wrapper, className)}>
          {children ? children : <></>}
        </div>
      ) : mode === 'update' && initialize.current !== null ? (
        <div ref={childrenRef} className={classNames(styles.wrapper, className)}>
          {children}
          {getLoader(true)}
        </div>
      ) : (
        getLoader()
      )}
    </>
  )
}

export default WithLoaderWrapper
