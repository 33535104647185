import { ChangeEvent, FC, ReactNode } from 'react'

import { Select, SelectItem } from '@carbon/react'
import dayjs, { Dayjs } from 'dayjs'

type MonthSelectProps = {
  labelText: ReactNode
  id: string
  defaultValue?: Date
  value?: Date
  invalid?: boolean
  readOnly?: boolean
  invalidText?: ReactNode
  onChange?: (date: Date) => void
  name?: string
}

const MonthSelect: FC<MonthSelectProps> = (props) => {
  const { defaultValue, value, onChange, ...rest } = props

  const createMonthOptions = (): Dayjs[] => {
    const currentYear = dayjs().year()
    const monthsInYear = 12
    const options: Dayjs[] = []

    for (let i = 0; i < monthsInYear * 2; i++) {
      const year = currentYear + Math.floor(i / monthsInYear)
      const month = i % monthsInYear
      options.push(dayjs().year(year).month(month).startOf('month'))
    }

    return options
  }

  const format = 'YYYY-MM'

  const currentValue = value
    ? dayjs(value).startOf('month').format(format)
    : dayjs().startOf('month').format(format)

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedDate = dayjs(e.target.value, format).toDate()
    if (onChange) {
      onChange(selectedDate)
    }
  }

  return (
    <Select {...rest} value={currentValue} onChange={handleChange}>
      {createMonthOptions().map((date) => {
        const formattedValue = date.format(format)
        return (
          <SelectItem key={formattedValue} text={date.format('MMMM YYYY')} value={formattedValue} />
        )
      })}
    </Select>
  )
}

export default MonthSelect
