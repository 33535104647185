import { useMemo } from 'react'

import { mustParseDate } from '@helpers/date'
import { useRouterSearchParams } from '@helpers/router'

export const usePeriodDates = () => {
  const searchParams = useRouterSearchParams()
  const periodStartDateParam = searchParams.get('periodStartDate')
  const periodEndDateParam = searchParams.get('periodEndDate')

  const periodStartDate = useMemo(() => mustParseDate(periodStartDateParam), [periodStartDateParam])

  const periodEndDate = useMemo(() => mustParseDate(periodEndDateParam), [periodEndDateParam])

  const periodError = useMemo(
    () =>
      periodStartDate !== undefined &&
      periodEndDate !== undefined &&
      periodStartDate > periodEndDate
        ? 'Неправильная дата начала периода'
        : undefined,
    [periodStartDate, periodEndDate],
  )

  return { periodStartDate, periodEndDate, periodError }
}
