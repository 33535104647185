import { useMemo, useState } from 'react'

import { toJS } from 'mobx'

import { ICatalogTaskStatus } from '@src/modules/catalog/model/catalogStore'

import { $catalog } from '@library/providers/StoreProvider'

export const useStatusFilter = () => {
  const optionsStatus = useMemo(() => toJS($catalog.taskStatus), [])
  const [statuses, setStatuses] = useState<ICatalogTaskStatus[]>(optionsStatus)

  return {
    optionsStatus,
    statuses,
    setStatuses,
  }
}
