import React, { Dispatch, FC, memo, SetStateAction } from 'react'

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@carbon/react'

import FilesTab from '@src/modules/files/ui/FilesTab'

import { FileItem } from '@services/models/health-check'

interface Props {
  addFilesForNewHC: Dispatch<SetStateAction<FileItem[]>>
}

const AddFilesTab: FC<Props> = ({ addFilesForNewHC }) => {
  return (
    <Tabs>
      <TabList aria-label="list-of-comments-and-history" contained={true} fullWidth={true}>
        <Tab>Файлы</Tab>
      </TabList>
      <TabPanels>
        <TabPanel className="p-0">
          <FilesTab healthCheckId={'0'} addFilesForNewHC={addFilesForNewHC} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default memo(AddFilesTab)
