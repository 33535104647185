import React, { FC, useEffect, useMemo, useState } from 'react'

import { Popover, PopoverContent, Tag } from '@carbon/react'
import { PopoverAlignment } from '@carbon/react/lib/components/Popover'
import { TYPES } from '@carbon/react/lib/components/Tag/Tag'
import classNames from 'classnames'

import styles from './EditStatus.module.scss'

type IItem = {
  id: string
  originalId: string
  type: string
  text: string
}

type IProps = {
  items: Array<{
    value: string
    text: string
  }>
  value: string
  onChange: (item: IItem) => boolean
  colorMapping?: { [key: string]: keyof typeof TYPES }
  defaultColor?: keyof typeof TYPES
  align?: PopoverAlignment
  disabled?: boolean
}

const EditStatus: FC<IProps> = ({
  items,
  value,
  onChange,
  colorMapping,
  defaultColor = 'cool-gray',
  align = 'bottom',
  disabled = false,
}) => {
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState(_.toLower(value))

  useEffect(() => {
    setStatus(_.toLower(value))
  }, [value])

  const isDisabled = disabled

  const configs = useMemo(() => {
    let result: IItem[] = []
    colorMapping = _.mapKeys(colorMapping, (x, k) => _.toLower(k))

    items.forEach((x) => {
      const id = _.toLower(x.value)

      result.push({
        id,
        originalId: x.value,
        type: colorMapping?.[id] || defaultColor,
        text: x.text,
      })
    })

    return result
  }, [items])

  const currentConfig = configs.find((x) => x.id === status) || {
    id: _.toLower(items[0]?.value),
    originalId: _.toLower(items[0]?.value),
    type: defaultColor,
    text: status,
  }

  const handleOnSelect = async (item: IItem) => {
    const isSuccess = await onChange(item)

    if (isSuccess) {
      setStatus(item.id)
      setOpen(false)
    }
  }

  return (
    <Popover
      open={open}
      align={align}
      onRequestClose={() => {
        setOpen(false)
      }}
      className={classNames(isDisabled && styles.disabled)}
      onClick={(e: any) => {
        e.stopPropagation()
      }}
    >
      <Tag
        as="span"
        // @ts-ignore
        type={currentConfig.type}
        onClick={() => {
          if (isDisabled) {
            return
          }

          setOpen(!open)
        }}
        className={styles.tag}
      >
        {currentConfig.text}
      </Tag>
      {!isDisabled && (
        <PopoverContent className={styles.content}>
          {configs.map(
            (item) =>
              item.id !== status && (
                <Tag
                  key={item.id}
                  as="div"
                  // @ts-ignore
                  type={item.type}
                  onClick={async () => await handleOnSelect(item)}
                  className={styles.tag}
                >
                  {item.text}
                </Tag>
              ),
          )}
        </PopoverContent>
      )}
    </Popover>
  )
}

export default EditStatus
