import {
  DepartmentTreeItem,
  StructureTreeNode,
} from '@src/modules/structureTree/StructureTree.types'

import { isAbsoluteEmpty } from '@helpers/other'

import { Department } from '@services/models/catalog'
import { PersonCardForList } from '@services/models/person'

export function departmentsAsTree(data: Department[]): DepartmentTreeItem[] {
  const departments = _.cloneDeep(data) as DepartmentTreeItem[]
  const departmentIdxMap = new Map<string, number>()

  for (let i = 0; i < data.length; i += 1) {
    const key: string | undefined = data[i].code

    if (key) {
      departmentIdxMap.set(key, i)
    }
  }

  let roots = []

  for (let i = 0; i < departments.length; i += 1) {
    const node = departments[i]

    node.itemType = 'department'

    if (node.children === undefined) {
      node.children = []
    }

    if (node.persons === undefined) {
      node.persons = []
    }

    const parentIdx = departmentIdxMap.get(node.parent!)

    if (node.parent! && parentIdx !== undefined) {
      // if you have dangling branches check that map[node.parentId] exists
      if (departments[parentIdx].children === undefined) {
        departments[parentIdx].children = []
      }

      departments[parentIdx].children!.push(node)
    } else {
      roots.push(node)
    }
  }

  return roots
}

export function enrichDepartmentsTreeWithPersons(
  node: DepartmentTreeItem | DepartmentTreeItem[],
  persons: PersonCardForList[],
): DepartmentTreeItem | DepartmentTreeItem[] {
  if (Array.isArray(node)) {
    return node.map((x) => enrichDepartmentsTreeWithPersons(x, persons)) as DepartmentTreeItem[]
  }

  const personPerDeps = persons.filter((x) => x.department?.code === node.code)

  if (personPerDeps.length) {
    node.persons = _.orderBy(
      personPerDeps,
      [
        // (x) => (x.nickName === node.manager ? -1 : 1),
        (x) => _.toLower(x.nickName),
        //
      ],
      ['asc', 'asc'],
    ).map((x) => ({
      ...x,
      itemType: 'person',
      children: [],
    }))
  }

  if (Array.isArray(node.children)) {
    node.children = enrichDepartmentsTreeWithPersons(node.children, persons) as DepartmentTreeItem[]
  }

  return node as DepartmentTreeItem
}

type ISearchMap = Record<string, string>

function getPath(arr: any[]) {
  return arr.filter((x) => !isAbsoluteEmpty(x)).join('.')
}

export function createSearchMap(
  node: StructureTreeNode | StructureTreeNode[],
  path: string = '',
  values: string[] = [],
) {
  let map: ISearchMap = {}

  if (!node) {
    return map
  }

  if (Array.isArray(node)) {
    node.forEach((child, index) => {
      map = { ...map, ...createSearchMap(child, getPath([path, index]), values) }
    })
  } else {
    values = [...values]

    if (node.itemType === 'department') {
      if (node.name) {
        values.push(node.name)
      }
      // if (node.code) values.push(node.code)
      // if (node.manager) values.push(node.manager)
    } else if (node.itemType === 'person') {
      if (node.name) {
        values.push(node.name)
      }

      if (node.nickName) {
        values.push(node.nickName)
      }

      // if (node.manager) values.push(node.manager)
      // if (node.functionalManager) values.push(node.functionalManager)
      if (node.jobPosition) {
        values.push(node.jobPosition)
      }
    }

    map[path] = values.join(' ').toLowerCase()

    if (node.itemType === 'department' && Array.isArray(node.persons)) {
      map = { ...map, ...createSearchMap(node.persons, getPath([path, 'persons']), values) }
    }

    if (Array.isArray(node.children)) {
      map = { ...map, ...createSearchMap(node.children, getPath([path, 'children']), values) }
    }
  }

  return map
}

export function filterSearchMap(map: ISearchMap, str: string = '') {
  const searchString = str.trim()

  if (searchString === '') {
    return map
  }

  const needle = searchString.toLowerCase().split(' ')

  return _.pickBy(map, (value) => {
    return needle.every((x) => value.includes(x))
  })
}

export function filterTreeData(
  node: StructureTreeNode | StructureTreeNode[],
  searchMap: ISearchMap,
  path: string = '',
): StructureTreeNode | StructureTreeNode[] | undefined {
  if (!node) {
    return node
  }

  if (path === '') {
    node = _.cloneDeep(node)
  }

  if (Array.isArray(node)) {
    return node
      .map((x, index) => filterTreeData(x, searchMap, getPath([path, index])))
      .filter((x) => x !== undefined) as StructureTreeNode[]
  }

  const { [path]: selfSearchItem, ...otherSearchItems } = searchMap
  const filteredSearchMap = _.pickBy(otherSearchItems, (value, key) => {
    return path === '' || key.startsWith(path + '.')
  })
  node.cardNotBright = false

  if (Object.keys(filteredSearchMap).length) {
    if (node.itemType === 'department' && node.persons && Array.isArray(node.persons)) {
      node.persons = filterTreeData(
        node.persons,
        filteredSearchMap,
        getPath([path, 'persons']),
      ) as undefined as never
    }

    if (Array.isArray(node.children)) {
      node.children = filterTreeData(
        node.children,
        filteredSearchMap,
        getPath([path, 'children']),
      ) as undefined as never
    }

    // @ts-ignore
    node.cardNotBright = selfSearchItem === undefined
  } else if (selfSearchItem === undefined) {
    return undefined
  }

  return node
}
