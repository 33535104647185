import React, { type FC, useMemo } from 'react'
import { Bar } from 'react-chartjs-2'

import {
  emptyPlaceHolderPlugin,
  integerTicks,
  labelFormatter,
} from '../../model/helpers/formatters'
import { DepartmentStats } from '../../model/types/pep.types'

interface PepCountChartProps {
  departmentStats: DepartmentStats[]
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Сотрудники с ИПР',
    },
  },
  scales: {
    x: {},
    y: {
      beginAtZero: true,
      ticks: integerTicks,
    },
  },
}

const PepCountChart: FC<PepCountChartProps> = ({ departmentStats }) => {
  const data = useMemo(() => {
    const labels = departmentStats.map(labelFormatter)
    const withPep = departmentStats.map((x) => x.pepCount)
    const withoutPep = departmentStats.map((x) => x.personCount - x.pepCount)

    return {
      labels,
      datasets: [
        {
          label: 'С ИПР',
          data: withPep,
          backgroundColor: '#59E500',
          barThickness: 40,
        },
        {
          label: 'Без ИПР',
          data: withoutPep,
          backgroundColor: '#FF5703',
          barThickness: 40,
        },
      ],
    }
  }, [departmentStats])

  return <Bar options={options} data={data} plugins={[...emptyPlaceHolderPlugin]} />
}

export default PepCountChart
