import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useController, UseControllerProps, useWatch } from 'react-hook-form'

import { Select, SelectItem } from '@carbon/react'
import dayjs from 'dayjs'

import { PERFORMANCE_CAUSE } from '@src/library/utils/constants'

import { $user } from '@library/providers/StoreProvider'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

import { devPlanService } from '@services'
import { ActionPlan } from '@services/models/health-check'

interface IProps extends UseControllerProps {
  labelText: ReactNode
  readOnly: boolean
}

const PerformanceReviewDropdown = (props: IProps) => {
  const [performanceReviews, setPerformanceReviews] = useState<ActionPlan[]>([])
  const [loaded, setLoaded] = useState(false)

  const {
    field,
    fieldState: { invalid, error },
    formState: { defaultValues },
  } = useController(props)

  const values = useWatch({
    control: props.control,
  })

  async function loadPerformanceReviews(person: string) {
    let resultList: ActionPlan[] = []
    setLoaded(false)
    const startDate = $user.hasPerm('PERM_PLAN_PERFORMANCE_MANAGER_READ') ? '2000-01-01' : undefined

    const response = await devPlanService.fetchPlanPerformance({
      personNickName: person,
      startDate,
    })

    if (response.isSuccess && response.data?.data) {
      resultList = response.data?.data.filter((x: ActionPlan) => x.performanceReview)
      const found = resultList.find(
        (x) => x.performanceReview!.reviewToken === field.value.causeObjectId,
      )

      if (resultList.length && !found) {
        field.onChange(getValue(resultList, resultList[0].performanceReview!.reviewToken!))
      }

      setPerformanceReviews(resultList)
    }

    setLoaded(true)
  }

  useEffect(() => {
    if (values.personObject.nickName && values.taskCause.causeType === PERFORMANCE_CAUSE) {
      loadPerformanceReviews(values.personObject.nickName)
    }
  }, [values.taskCause.causeType])

  function getTitle(ap: ActionPlan) {
    return `${dayjs(ap?.performanceReview?.assesmentDate).format('YYYY-MM-DD')}/${
      ap?.performanceReview?.campaignName ?? 'без имени'
    }`
  }

  function getValue(list: ActionPlan[], token: string) {
    const actionPlan = list.find((x) => x.performanceReview?.reviewToken === token)

    return {
      causeObjectId: token,
      causeText: getTitle(actionPlan!),
      causeType: PERFORMANCE_CAUSE,
      causeObjectLink: actionPlan?.performanceReview?.performanceUrl,
    }
  }

  const onChange = (e: any) => {
    const token = e.target.value
    field.onChange(getValue(performanceReviews, token))
  }

  const options = useMemo(() => {
    if (performanceReviews?.length > 0) {
      return performanceReviews.map((ap) => ({
        text: getTitle(ap),
        value: ap.performanceReview?.reviewToken,
      }))
    } else {
      setLoaded(true)

      return [
        {
          text: 'нет оценок по сотруднику',
          value: '',
          disabled: true,
        },
      ]
    }
  }, [performanceReviews])

  return (
    <WithLoaderWrapper isLoading={!loaded} size="small">
      <Select
        id={field.name}
        labelText={props.labelText}
        invalid={invalid}
        invalidText={error?.message}
        readOnly={props.readOnly}
        name={field.name}
        onChange={onChange}
        onBlur={field.onBlur}
        value={field.value.causeObjectId}
        ref={field.ref}
      >
        {options.map((x: any) => (
          <SelectItem key={x.value} text={x.text} value={x.value} disabled={x.disabled ?? false} />
        ))}
      </Select>
    </WithLoaderWrapper>
  )
}

export default PerformanceReviewDropdown
