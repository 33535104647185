import React, { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'

import {
  Button,
  ComboBox,
  DataTable,
  DatePickerInput,
  /* @ts-ignore */
  MultiSelect,
  /* @ts-ignore */
  OverflowMenu,
  OverflowMenuItem,
  PaginationNav,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from '@carbon/react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { Hook } from 'flatpickr/dist/types/options'
import { observer } from 'mobx-react-lite'

import { dateFormats } from '@src/library/utils/constants'
import { PaginationOptions } from '@src/library/utils/hooks'
import DepartmentSelect from '@src/modules/department/ui/DepartmentSelect'
import { EditTaskStatus } from '@src/modules/editTaskStatus'
import filterStyles from '@src/modules/filters/ui/styles/Filters.module.scss'
import TaskForm from '@src/modules/task/ui/TaskForm'

import { formatDate } from '@helpers/date'
import { useRouterSearchParams } from '@helpers/router'

import { $loader, $modal, $user } from '@library/providers/StoreProvider'
import HcmDatePicker from '@library/ui/datePicker/HcmDatePicker'
import CustomAlertModal from '@library/utils/modals/CustomAlertModal'

import { humanTaskService } from '@services'

import { useTaskAuthorFilter, useTaskPeriodFilter, useTaskStatusFilter } from '../model/hooks'
import styles from './TasksList.module.scss'

interface IProps {
  showDepartment?: boolean
  shouldShowAddTaskButton: boolean
  taskList: any[]
  loadData: Function
  shouldShowProfileLink: Function
  onSearchChange: Function
  showAutorsFilter?: boolean
  showDepartmentColumn?: boolean
  paginationOptions: PaginationOptions
}

const HCM_SYSTEM = 'hcm_system'

const TasksList: FC<IProps> = ({
  taskList,
  loadData,
  shouldShowProfileLink,
  shouldShowAddTaskButton,
  showDepartment = false,
  showAutorsFilter = true,
  showDepartmentColumn = true,
  paginationOptions: { sortBy, handleHeaderClick, page, setPage, totalItems, itemsShown },
  onSearchChange,
}) => {
  const searchParams = useRouterSearchParams()
  const taskId = searchParams.get('id')
  const { authors, isAuthorsLoading, author, setAuthor } = useTaskAuthorFilter()

  const { statusesList, selectedStatuses, setSelectedStatuses } = useTaskStatusFilter()
  const { startDate, setStartDate, endDate, setEndDate } = useTaskPeriodFilter()

  useEffect(() => {
    if (taskId) {
      $modal.add(TaskForm, { taskId })
    }
  }, [taskId])

  const handleAdd = () => {
    $modal.add(TaskForm)
  }

  function handleOpen(taskId: string, edit?: boolean) {
    $modal.add(TaskForm, { taskId, edit })
  }

  function handleDelete(id: string) {
    $modal.add(CustomAlertModal, {
      title: 'Удаление задачи',
      message: `Вы точно хотите удалить задачу ${id}?`,
      onDone: $loader.registerHandler('task-delete-form', async () => {
        await humanTaskService.deleteTask(id)
      }),
      onDoneButtonType: 'danger',
    })
  }

  const handleSearch = (e: any) => {
    onSearchChange(e.target.value)
  }

  const onStartDateChange: Hook = (dates: any) => {
    setStartDate(dates[0])
  }

  const onEndDateChange: Hook = (dates: any) => {
    setEndDate(dates[0])
  }

  const debouncedHandleSearch = _.debounce(handleSearch, 500)

  useEffect(() => {
    const ids = ['task-create-or-update-form', 'edit-task-status', 'task-delete-form']
    const subIds = $loader.subscribe(ids, () => loadData())

    return () => $loader.unsubscribe(ids, subIds)
  })

  const headers = [
    { key: 'code', header: 'Код', isSortable: true },
    { key: 'createDate', header: 'Дата создания', isSortable: true },
    { key: 'dueTo', header: 'Выполнить до', isSortable: true },
    { key: 'personObject', header: 'С кем поработать (сотрудник)', isSortable: true },
    ...(showDepartmentColumn
      ? [{ key: 'departmentObject', header: 'С кем поработать (подразделение)', isSortable: true }]
      : []),
    { key: 'author', header: 'Автор', isSortable: true },
    { key: 'theme', header: 'Суть задачи' },
    { key: 'performer', header: 'Исполнитель', isSortable: true },
    { key: 'status', header: 'Статус', isSortable: true },
  ]

  const rows = taskList.map((x: any) => ({
    id: x.id,
    code: x.code,
    createDate: dayjs(x.createDate).format(dateFormats.date),
    dueTo: dayjs(x.dueTo).format(dateFormats.date),
    personObject: x.personObject?.nickName,
    author: x.author,
    theme: x.theme,
    performer: x.performer.nickName,
    status: <EditTaskStatus task={x} align="bottom-right" />,
    ...(showDepartmentColumn && { departmentObject: x.departmentObject?.name }),
  }))

  return (
    <>
      <div className={styles.filterToolbar}>
        {showDepartment ? <DepartmentSelect /> : <div className={styles.toolbarSpacer} />}
        <div className={styles.extraFilters}>
          <div className="cds--form-item">
            <MultiSelect
              id="taskStatuses"
              className={classNames(styles.taskStatusesFilter, 'multiselect-filter--without-tag')}
              label={
                selectedStatuses.length
                  ? selectedStatuses.map((x) => x.shortName).join(', ')
                  : 'Все'
              }
              onChange={(x: any) => setSelectedStatuses(x.selectedItems)}
              titleText="статус"
              initialSelectedItems={
                selectedStatuses
              } /* it can't be controlled as it's linked to router state */
              items={statusesList}
              itemToString={(x: any) => (x ? x.shortName : '')}
              size="sm"
            />
          </div>
          <HcmDatePicker onChange={onStartDateChange} onClose={onStartDateChange}>
            <DatePickerInput
              id="from"
              size="sm"
              defaultValue={formatDate(startDate)}
              labelText="с"
              placeholder="начало"
            />
          </HcmDatePicker>
          <HcmDatePicker onChange={onEndDateChange} onClose={onEndDateChange}>
            <DatePickerInput
              size="sm"
              defaultValue={formatDate(endDate)}
              id="to"
              labelText="по"
              placeholder="окончание"
            />
          </HcmDatePicker>
          {showAutorsFilter && (
            <div className="cds--form-item">
              <ComboBox
                /* @ts-ignore */
                id="author"
                titleText="автор"
                items={authors}
                itemToString={(item: any) => (item ? item.text : '')}
                onChange={(item: any) => setAuthor(item?.selectedItem?.text)}
                disabled={isAuthorsLoading}
                className={filterStyles.comboBox}
                size="sm"
              />
            </div>
          )}
        </div>
      </div>

      <DataTable headers={headers} rows={rows} isSortable={false}>
        {({
          rows,
          headers,
          getTableProps,
          getTableContainerProps,
          getHeaderProps,
          getRowProps,
          getToolbarProps,
        }) => {
          return (
            <TableContainer {...getTableContainerProps()} className="customCds">
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent className={styles.toolbarContent}>
                  <TableToolbarSearch onChange={debouncedHandleSearch} placeholder="Поиск" />
                  {shouldShowAddTaskButton && (
                    <Button onClick={handleAdd} kind="primary">
                      Создать задачу
                    </Button>
                  )}
                </TableToolbarContent>
              </TableToolbar>

              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: any) => (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        isSortable={header.isSortable}
                        onClick={() => handleHeaderClick(header.key)}
                        isSortHeader={sortBy === header.key}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                    <TableHeader key="action" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow {...getRowProps({ row })}>
                      {row.cells.map((cell) => {
                        if (
                          (cell.info.header === 'author' || cell.info.header === 'performer') &&
                          shouldShowProfileLink(cell)
                        ) {
                          return (
                            <TableCell key={cell.id}>
                              {cell.value === HCM_SYSTEM ? (
                                cell.value
                              ) : (
                                <Link to={'/user-profile/' + cell.value}>{cell.value}</Link>
                              )}
                            </TableCell>
                          )
                        } else if (cell.info.header === 'code') {
                          return (
                            <TableCell key={cell.id}>
                              <div className="a" onClick={() => handleOpen(row.id)}>
                                {cell.value}
                              </div>
                            </TableCell>
                          )
                        }

                        return <TableCell key={cell.id}>{cell.value}</TableCell>
                      })}
                      <TableCell key={'action:' + row.id}>
                        <OverflowMenu aria-label="overflow-menu" flipped={true}>
                          <OverflowMenuItem
                            itemText="Просмотр"
                            onClick={() => handleOpen(row.id)}
                          />
                          {$user.canEditTask(row.cells[4].value) && (
                            <OverflowMenuItem
                              itemText="Редактировать"
                              onClick={() => handleOpen(row.id, true)}
                            />
                          )}
                          {$user.hasPerm('PERM_TASK_DELETE') && (
                            <OverflowMenuItem
                              itemText="Удалить"
                              hasDivider={true}
                              isDelete={true}
                              onClick={() => handleDelete(row.id)}
                            />
                          )}
                        </OverflowMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }}
      </DataTable>
      <PaginationNav
        className="pagination"
        page={page}
        totalItems={totalItems}
        onChange={setPage}
        itemsShown={itemsShown}
      />
    </>
  )
}

export default observer(TasksList)
