import React, { useMemo, useState } from 'react'

import { Button, Checkbox, DatePickerInput, FilterableMultiSelect } from '@carbon/react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import { useLoader } from '@src/library/utils/hooks'
import {
  OnboardingsChartCount,
  OnboardingsChartYearMetrics,
  OnboardingsListTable,
  useHRPPFilter,
  usePeriodFilter,
  usePersonsFilter,
} from '@src/modules/analytic'
import DepartmentSelect from '@src/modules/department/ui/DepartmentSelect'
import { useFirstDepartment } from '@src/pages/analytics/model/AnalyticsPage.hooks'

import { formatDate } from '@helpers/date'

import { $filter } from '@library/providers/StoreProvider'
import HcmDatePicker from '@library/ui/datePicker/HcmDatePicker'
import { UIColorsObject } from '@library/ui/UIColors'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

import { dashboardService } from '@services'
import { OnboardingAnalytics, OnboardingFilter } from '@services/models/dashboard'
import { OnboardingsFilter } from '@services/models/person'

import styles from './OnboardingsAnalyticsPage.module.scss'

const OnboardingsAnalyticsPage = () => {
  // filters
  useFirstDepartment()

  const [isHierarchy, setIsHierarchy] = useState<boolean>(true)
  const { startDate, setStartDate, endDate, setEndDate, resetDates } = usePeriodFilter()
  const { mappedPersons, isPersonsLoading } = usePersonsFilter()
  const { hrppsOptions, isHRPPLoading } = useHRPPFilter()
  const [hrpps, setHrpps] = useState<typeof mappedPersons>([])
  const [mentors, setMentors] = useState<typeof mappedPersons>([])

  const filters: OnboardingFilter | OnboardingsFilter = useMemo(() => {
    return {
      departments: $filter.selectedCodes,
      departmentHierarchy: isHierarchy,
      startDateFrom: startDate || undefined,
      startDateTo: endDate || undefined,
      hrpps: hrpps.map((x) => x.value),
      mentors: mentors.map((x) => x.value),
    }
  }, [$filter.selectedCodes, isHierarchy, startDate, endDate, hrpps, mentors])

  const handleClearFilter = () => {
    $filter.SET_SELECTED_CODES([])
    setIsHierarchy(true)
    resetDates()
    setHrpps([])
    setMentors([])
  }

  // result data
  const [analytics, setAnalytics] = useState<OnboardingAnalytics[]>([])

  const isLoading = useLoader(async () => {
    const response = await dashboardService.getOnboardingAnalytics(filters)

    if (response.isSuccess && response.data) {
      setAnalytics(response.data)
    }
  }, [filters])

  return (
    <>
      <div className={classNames(styles.filters)}>
        <div className={styles.filtersItem}>
          <DepartmentSelect />
        </div>
        <div className={classNames(styles.filtersItem, styles.hierarchyCheckbox)}>
          <Checkbox
            id="check-hierarchy"
            labelText="Учитывать иерархию подразделений"
            checked={isHierarchy}
            onChange={() => setIsHierarchy(!isHierarchy)}
          />
        </div>
      </div>

      <div className={classNames(styles.filters, 'mt-20')}>
        <HcmDatePicker
          value={formatDate(startDate, { format: 'date' })}
          onChange={(dates) => setStartDate(dates[0])}
          className={classNames(styles.filtersItem, styles.calendar)}
        >
          <DatePickerInput
            id="startDate"
            labelText="Дата начала, С"
            placeholder="начало"
            size="lg"
          />
        </HcmDatePicker>
        <HcmDatePicker
          value={formatDate(endDate, { format: 'date' })}
          onChange={(dates) => setEndDate(dates[0])}
          className={classNames(styles.filtersItem, styles.calendar)}
        >
          <DatePickerInput id="endDate" labelText="По" placeholder="окончание" size="lg" />
        </HcmDatePicker>
        {/* @ts-ignore */}
        <FilterableMultiSelect<typeof hrppsOptions>
          key={['hrpps', hrpps.length].join('_')}
          filterable={true}
          id="hrpps"
          placeholder={hrpps.map((x) => x.text).join(', ')}
          onChange={(x) => setHrpps(x.selectedItems)}
          selectedItems={hrpps}
          items={hrppsOptions}
          itemToString={(x) => x.text}
          titleText="HRPP"
          size="lg"
          className={classNames(styles.filtersItem, styles.filterableSelect)}
          disabled={isHRPPLoading}
        />
        {/* @ts-ignore */}
        <FilterableMultiSelect<typeof mappedPersons>
          key={['mentors', mentors.length].join('_')}
          filterable={true}
          id="mentors"
          placeholder={mentors?.length ? mentors?.map((x) => x.text).join(', ') : ''}
          onChange={(x) => setMentors(x.selectedItems)}
          selectedItems={mentors}
          items={mappedPersons}
          itemToString={(x) => x.text}
          titleText="Ментор"
          size="lg"
          className={classNames(styles.filtersItem, styles.filterableSelect)}
          disabled={isPersonsLoading}
        />
        <div className={classNames(styles.filtersItem, styles.clearButton)}>
          <Button kind="primary" type="submit" onClick={handleClearFilter}>
            Очистить фильтр
          </Button>
        </div>
      </div>

      <WithLoaderWrapper isLoading={isLoading} mode={'update'}>
        <div className={classNames(styles.charts, 'mt-30')}>
          <div className={styles.chartsItem}>
            <OnboardingsChartCount
              analytics={analytics}
              title={'Количество сотрудников на адаптации'}
              datasetLabel={'Количество сотрудников'}
              primaryColor={UIColorsObject.blue}
              dataKey={'personCount'}
              className={styles.chartsItemChart}
            />
          </div>
          <div className={styles.chartsItem}>
            <OnboardingsChartCount
              analytics={analytics}
              title={'Количество планов без ссылки'}
              datasetLabel={'Количество планов'}
              primaryColor={UIColorsObject.yellow}
              dataKey={'noLinkCount'}
              className={styles.chartsItemChart}
            />
          </div>
          <div className={styles.chartsItem}>
            <OnboardingsChartCount
              analytics={analytics}
              title={'Количество досрочно завершенных адаптаций'}
              datasetLabel={'Количество адаптаций'}
              primaryColor={UIColorsObject.green}
              dataKey={'earlyCount'}
              className={styles.chartsItemChart}
            />
          </div>
          <div className={styles.chartsItem}>
            <OnboardingsChartCount
              analytics={analytics}
              title={'Количество продленных адаптаций'}
              datasetLabel={'Количество адаптаций'}
              primaryColor={UIColorsObject.orange}
              dataKey={'lateCount'}
              className={styles.chartsItemChart}
            />
          </div>
          <div className={classNames(styles.chartsItem, styles.chartsItemFullWith)}>
            <OnboardingsChartYearMetrics
              analytics={analytics}
              title={'Средняя продолжительность адаптационного периода, дней'}
              className={styles.chartsItemChart}
            />
          </div>
        </div>
        <OnboardingsListTable filters={filters} className={classNames(styles.table, 'mt-30')} />
      </WithLoaderWrapper>
    </>
  )
}

export default observer(OnboardingsAnalyticsPage)
