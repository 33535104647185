import React, { FC, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  DataTable,
  /* @ts-ignore */
  OverflowMenu,
  OverflowMenuItem,
  PaginationNav,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import { formErrors } from '@src/library/utils/constants'
import { useLoader, usePagination } from '@src/library/utils/hooks'
import EditFeedbackStatus from '@src/modules/feedback/ui/editFeedbackStatus/EditFeedbackStatus'

import { formatDate } from '@helpers/date'

import { $catalog, $loader } from '@library/providers/StoreProvider'
import DownloadFiles from '@library/ui/downloadFiles/DownloadFiles'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'
import { showErrorAlert } from '@library/utils/toast'

import { notificationsService } from '@services'
import {
  FeedbackFilter,
  FeedbackItem,
  FeedbackItemAttachment,
} from '@services/models/notifications'

import styles from './FeedbackListTable.module.scss'

type SortByType = Parameters<typeof notificationsService.getFeedbackList>[1]['sortBy']

type IProps = {
  filters: FeedbackFilter
  className?: string
}

const FeedbackListTable: FC<IProps> = ({ filters, className }) => {
  const [dataList, setDataList] = useState<FeedbackItem[] | []>([])
  const pagination = usePagination<SortByType>({ defaultSortBy: 'id', defaultSortOrder: 'desc' })

  const loadData = async () => {
    const response = await notificationsService.getFeedbackList(filters, {
      limit: pagination.limit,
      offset: pagination.offset,
      sortBy: pagination.sortBy,
      sortOrder: pagination.sortOrder,
    })

    if (response.isSuccess && response.data?.data) {
      if (response.isSuccess && response.data?.data) {
        setDataList(response.data.data)
        pagination.setPagingCount(response.data.paging?.count ?? 0)
      } else {
        setDataList([])
        pagination.setPagingCount(0)
      }
    }
  }

  const isLoading = useLoader(async () => {
    await loadData()
  }, [filters, pagination.sortBy, pagination.sortOrder, pagination.page])

  useEffect(() => {
    const ids = ['user-feedback-form', 'edit-feedback-status']
    const subIds = $loader.subscribe(ids, () => loadData())

    return () => $loader.unsubscribe(ids, subIds)
  }, [])

  const headers: Array<{
    key: keyof FeedbackItem | 'action'
    header: string
    isSortable?: boolean
  }> = [
    { key: 'id', header: 'ID', isSortable: true },
    { key: 'type', header: 'Тип', isSortable: true },
    { key: 'subject', header: 'Краткое описание' },
    { key: 'description', header: 'Описание' },
    { key: 'personNickName', header: 'Пользователь', isSortable: true },
    { key: 'attachment', header: 'Вложения' },
    { key: 'status', header: 'Статус', isSortable: true },
    { key: 'author', header: 'Автор', isSortable: true },
    { key: 'createDate', header: 'Дата создания', isSortable: true },
    // { key: 'action', header: '' },
  ]

  const options = () => (
    <OverflowMenu aria-label="overflow-menu" flipped={true}>
      <OverflowMenuItem itemText="Просмотр" onClick={() => {}} />
    </OverflowMenu>
  )

  const getAttachment = (item: FeedbackItem) => {
    return (
      <DownloadFiles
        files={item.attachment!}
        callback={async (file: FeedbackItemAttachment) => {
          const response = await notificationsService.downloadFeedbackFile(item.id!, file.id!)

          if (response.isSuccess && response.data) {
            return response.data
          } else {
            showErrorAlert(formErrors.somethingWentWrong)
          }
        }}
        className={styles.colAttachment}
      />
    )
  }

  const rows = useMemo(() => {
    return dataList.map((item, index) => ({
      id: `${item?.id}` || [index, item.personNickName].join('_'),
      type: $catalog.feedbackType.find((x) => x.value === item.type)?.shortName || item.type,
      subject: item.subject,
      description: <div className={styles.colDescription}>{item.description}</div>,
      personNickName: item.personNickName,
      attachment: getAttachment(item),
      status: (isLast: boolean) => (
        <EditFeedbackStatus item={item} align={isLast ? 'top-right' : 'bottom-right'} />
      ),
      author: item.author,
      createDate: formatDate(item.createDate),
      // action: options(),
    }))
  }, [dataList, $catalog.feedbackType])

  return (
    <WithLoaderWrapper
      isLoading={isLoading}
      mode={'update'}
      className={classNames(styles.table, className)}
    >
      <DataTable headers={headers} rows={rows} isSortable={false}>
        {({
          rows,
          headers,
          getTableProps,
          getTableContainerProps,
          getHeaderProps,
          getRowProps,
        }) => {
          return (
            <TableContainer {...getTableContainerProps()} className={'table-without-hover'}>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: any) => (
                      <TableHeader
                        {...getHeaderProps({ header })}
                        isSortHeader={pagination.sortBy === header.key}
                        isSortable={header?.isSortable}
                        sortDirection={pagination.sortOrder?.toUpperCase()}
                        onClick={() => pagination.handleHeaderClick(header.key)}
                        key={header?.key}
                      >
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.length > 0 ? (
                    rows.map((row, index) => (
                      <TableRow {...getRowProps({ row })} key={'row' + row?.id}>
                        {row.cells.map((cell) => {
                          const cellKey = [row.id, cell.id].join('_')

                          if (['status'].includes(cell.info.header)) {
                            return (
                              <TableCell key={cellKey}>
                                {cell.value(rows.length - 1 === index)}
                              </TableCell>
                            )
                          } else if (['author', 'personNickName'].includes(cell.info.header)) {
                            return (
                              <TableCell key={cellKey}>
                                <Link to={'/user-profile/' + cell.value}>{cell.value}</Link>
                              </TableCell>
                            )
                          }

                          return <TableCell key={cellKey}>{cell.value}</TableCell>
                        })}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="mt-20">
                      <TableCell colSpan={headers.length}>Нет записей</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }}
      </DataTable>
      <PaginationNav
        page={pagination.page}
        totalItems={pagination.totalItems}
        onChange={pagination.setPage}
        itemsShown={pagination.itemsShown}
      />
    </WithLoaderWrapper>
  )
}

export default observer(FeedbackListTable)
