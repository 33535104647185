import React, { FC } from 'react'

import { FormLabel, Tag } from '@carbon/react'
import classNames from 'classnames'

import { formatDate } from '@helpers/date'

import styles from './AuthorAndCreateDate.module.scss'

type IProps = {
  author: string | undefined
  createDate: Date | undefined
}

const AuthorAndCreateDate: FC<IProps> = ({ author, createDate }) => {
  return (
    <div className={classNames('cds--form-item')}>
      <div className={classNames(styles.labelRow)}>
        <FormLabel className={classNames(styles.label)}>Автор:</FormLabel>
        <FormLabel className={classNames(styles.label, styles.labelBold)}>
          <Tag>{author}</Tag>
        </FormLabel>
        <FormLabel className={classNames(styles.label)}>Дата создания:</FormLabel>
        <FormLabel className={classNames(styles.label, styles.labelBold)}>
          {formatDate(createDate)}
        </FormLabel>
      </div>
    </div>
  )
}

export default AuthorAndCreateDate
