import React, { ChangeEvent, Dispatch, FC, memo, SetStateAction, useEffect, useState } from 'react'

import { Close } from '@carbon/icons-react'
import {
  Column,
  DataTable,
  Row,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react'

import { formErrors } from '@src/library/utils/constants'

import { formatDate } from '@helpers/date'
import { toBase64 } from '@helpers/other'

import { $loader } from '@library/providers/StoreProvider'
import { showErrorAlert } from '@library/utils/toast'

import { devPlanService } from '@services'
import { FileItem } from '@services/models/health-check'

import styles from './FilesTab.module.scss'

type IProps = {
  healthCheckId: string
  addFilesForNewHC?: Dispatch<SetStateAction<FileItem[]>>
}

const INPUT_ID = 'download-files-hc'

const FilesTab: FC<IProps> = ({ healthCheckId, addFilesForNewHC }) => {
  const [files, setFiles] = useState<FileItem[]>([])
  const isNewHC = healthCheckId === '0'

  useEffect(() => {
    if (!isNewHC) {
      getFiles()
    }
  }, [healthCheckId])

  const getFiles = async () => {
    const response = await devPlanService.getFilesHC(healthCheckId)

    if (response.isSuccess) {
      const data = (response.data?.data as FileItem[]) || []
      setFiles(data)
    }
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files as FileList
    const selectedFile = fileList[0] as File
    await handleSubmit(selectedFile)
    const input = e.target as HTMLInputElement
    input.value = ''
  }

  const handleSubmit = $loader.registerHandler(INPUT_ID, async (file: File) => {
    const formData = new FormData()

    if (file) {
      const encodeFile = (await toBase64(file)) as string
      const base64SType = (encodeFile as string)?.split(',')[0]
      const base64SFile = (encodeFile as string)?.split(',')[1]

      //убираем префикс `data:` и постфикс `;base64`
      const rawType = base64SType.replace(';base64', '').substring(5)

      formData.append('file', base64SFile)
      formData.append('fileName', file?.name)
      formData.append('fileType', rawType)

      const resAdd = await devPlanService.addFileHC({ id: healthCheckId, body: formData })

      if (resAdd.isSuccess) {
        const newFile = resAdd?.data as FileItem
        setFiles((prev) => [...prev, newFile])

        if (isNewHC && newFile && addFilesForNewHC) {
          addFilesForNewHC((prev) => [...prev, newFile])
        }
      } else {
        showErrorAlert(formErrors.somethingWentWrong)
      }
    }
  })

  const handleDeleteFile = async (fileId: string) => {
    const resAdd = await devPlanService.removeFileHC(healthCheckId, fileId)

    if (resAdd.isSuccess) {
      setFiles((prev) => prev?.filter((file) => file?.id !== fileId))

      if (addFilesForNewHC) {
        addFilesForNewHC((prev) => prev?.filter((file) => file?.id !== fileId))
      }
    } else {
      showErrorAlert(formErrors.somethingWentWrong)
    }
  }

  function downloadFileFromBase64(
    base64Data: { file: string; fileType: string },
    fileName: string,
  ) {
    const url = `data:${base64Data.fileType};base64,${base64Data.file}`

    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const handledownloadFile = async (fileId: string, fileName: string) => {
    const resAdd = await devPlanService.downloadFileHC(healthCheckId, fileId)

    if (resAdd.isSuccess) {
      downloadFileFromBase64(resAdd?.data, fileName)
    } else {
      showErrorAlert(formErrors.somethingWentWrong)
    }
  }

  const headers = [
    { key: 'title', header: 'Наименование' },
    { key: 'user', header: 'Кто загрузил' },
    { key: 'date', header: 'Когда загрузил' },
    { key: 'btn', header: '' },
  ]

  const rows = files?.map((file: FileItem) => ({
    id: String(file?.id),
    title: (
      <a href="#" onClick={() => handledownloadFile(String(file?.id), file?.fileName as string)}>
        {file?.fileName}
      </a>
    ),
    user: file?.author,
    date: formatDate(file?.createDate),
    btn: <Close onClick={() => handleDeleteFile(String(file?.id))} fontSize="small" />,
  }))

  return (
    <div className="mt-20">
      <DataTable headers={headers} rows={rows} isSortable={false}>
        {({ rows, headers, getTableProps, getHeaderProps }) => {
          return (
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })} onClick={() => {}}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id} onClick={() => {}}>
                          {cell.value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }}
      </DataTable>
      <Row className={'mt-20'}>
        <Column lg={16} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <label className={` cds--btn cds--btn--primary ${styles.download}`}>
            <input type="file" onChange={handleFileChange} />
            <span className="input-file__text">Загрузить файл</span>
          </label>
        </Column>
      </Row>
    </div>
  )
}

export default memo(FilesTab)
