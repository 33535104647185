import { makeAutoObservable, runInAction } from 'mobx'

import { $root as rootStore } from '@library/providers/StoreProvider'

import { catalogService } from '@services'
import {
  ChannelChannelEnum,
  FeedbackItemStatusEnum,
  FeedbackItemTypeEnum,
  SettingNotificationTypeEnum,
} from '@services/models/notifications'

export type ICatalogTaskStatus = {
  value: 'ToDo' | 'InProgress' | 'Done' | string
  shortName: string
  description: string
}

export type ICatalogNotificationType = {
  value: SettingNotificationTypeEnum
  shortName: string
  description: string | null
}

export type ICatalogNotificationChannel = {
  value: ChannelChannelEnum
  shortName: string
  description: string | null
}

export type IUserRole = {
  value: string
  shortName: string
  description: string | null
}

export type ICatalogHealthCheckReason = {
  value: string
  shortName: string
  description: string
}

export type IFeedbackType = {
  value: FeedbackItemTypeEnum
  shortName: string
  description: string | null
}

export type IFeedbackStatus = {
  value: FeedbackItemStatusEnum
  shortName: string
  description: string | null
}

export class CatalogStore {
  $root

  constructor(store: typeof rootStore) {
    makeAutoObservable(this)
    this.$root = store
  }

  getCatalogByCode = (catalogs: any, code: string) => {
    const catalog = catalogs.find((item: any) => item.catalogCode === code)

    return catalog ? catalog.catalogRows : []
  }

  catalogs = []

  async loadCatalog() {
    const response = await catalogService.fetch() // api call

    if (response.isSuccess && response.data?.catalog) {
      const data = response.data?.catalog || []

      runInAction(() => {
        this.catalogs = data
      })
    }

    return this.catalogs
  }

  get healthCheckReason() {
    return this.getCatalogByCode(this.catalogs, 'HealthCheckReason') as ICatalogHealthCheckReason[]
  }

  get taskCauseType() {
    return this.getCatalogByCode(this.catalogs, 'TaskCauseType')
  }

  get fileType() {
    return this.getCatalogByCode(this.catalogs, 'FileType')
  }

  get taskStatus() {
    return this.getCatalogByCode(this.catalogs, 'TaskStatus') as ICatalogTaskStatus[]
  }

  get healthCheckReasonMap() {
    return new Map(this.healthCheckReason.map((obj: any) => [obj.value, obj.shortName]))
  }

  get notificationTypes(): ICatalogNotificationType[] {
    return this.getCatalogByCode(this.catalogs, 'NotificationType')
  }

  get notificationChannels(): ICatalogNotificationChannel[] {
    return this.getCatalogByCode(this.catalogs, 'NotificationChannel')
  }

  get feedbackType(): IFeedbackType[] {
    return this.getCatalogByCode(this.catalogs, 'FeedbackType')
  }

  get feedbackStatus(): IFeedbackStatus[] {
    return this.getCatalogByCode(this.catalogs, 'FeedbackStatus')
  }

  get rolesList(): IUserRole[] {
    return this.getCatalogByCode(this.catalogs, 'UserRole')
  }
}
