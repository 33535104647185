import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Select, SelectItem, Stack, TextInput } from '@carbon/react'
import { zodResolver } from '@hookform/resolvers/zod'

import MonthSelect from '@src/library/ui/monthSelect/MonthSelect'
import { useLoader } from '@src/library/utils/hooks'
import SingleDepartmentSelect from '@src/modules/filters/ui/form-tems/SingleDepartmentSelect'

import { currentQuarter } from '@helpers/date'

import { $catalog, $loader, $user } from '@library/providers/StoreProvider'
import AuthorAndCreateDate from '@library/ui/author-and-createdate/AuthorAndCreateDate'
import FormEditLink from '@library/ui/formEditLink/FormEditLink'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

import { catalogService, devPlanService } from '@services'
import { FileItem } from '@services/models/health-check'

import schema, { HealthCheckFormInputs } from '../model/types/HealthCheckValidationSchema'
import HealthCheckIndexes from './HealthCheckIndexes'

type HealthCheckFormFieldsProps = {
  id: string | undefined
  onFormSubmit?: Function
  onFormDataLoad?: Function
  formId: string
  readOnly?: boolean
  files?: FileItem[]
}

const HealthCheckFormFields: FC<HealthCheckFormFieldsProps> = ({
  id,
  onFormSubmit,
  onFormDataLoad,
  formId,
  readOnly = false,
  files,
}) => {
  const [departmentList, setDepartmentList] = useState<any>([])

  const loggedInUser = $user.loggedInUser

  const defaultValues = {
    author: loggedInUser.preferredUsername,
    startDate: currentQuarter(),
  }

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<HealthCheckFormInputs>({
    defaultValues,
    resolver: zodResolver(schema),
  })

  const isDepsCheckLoading = useLoader(async () => {
    const response = await catalogService.fetchDepartments(undefined)

    if (response.isSuccess && response.data) {
      const depsList = _.orderBy(response.data[0].data, 'name', 'asc')
      setDepartmentList(depsList)
    }
  })

  const isHealthCheckLoading = useLoader(async () => {
    if (id) {
      const response = await devPlanService.get(id!)

      if (response.isSuccess && response.data) {
        const inputs = response.data
        reset(inputs)
        onFormDataLoad && onFormDataLoad(response.data)
      }
    }
  })

  const isLoading = isDepsCheckLoading || isHealthCheckLoading

  const onSubmit: SubmitHandler<HealthCheckFormInputs> = $loader.registerHandler(
    'hc-create-or-update-form',
    async (data) => {
      let success = true
      if (!readOnly) {
        let response
        const payload = devPlanService.toApiPayload(data, files)

        if (id) {
          payload.id = id
          response = await devPlanService.updateHealthCheck(loggedInUser.preferredUsername, payload)
        } else {
          response = await devPlanService.createHealthCheck(loggedInUser.preferredUsername, payload)
        }

        success = response.isSuccess
      }

      onFormSubmit && onFormSubmit(success)
    },
  )

  const values = watch()

  return (
    <WithLoaderWrapper isLoading={isLoading}>
      <form id={formId} noValidate={true} onSubmit={handleSubmit(onSubmit)}>
        {id && <AuthorAndCreateDate author={values.author} createDate={values.createDate} />}
        <Stack gap={6}>
          <div className="cds--form-item mb-10">
            <SingleDepartmentSelect
              id="department"
              departmentCode={values?.department}
              departmentList={departmentList}
              onChange={(value) => setValue('department', value)}
              readOnly={readOnly}
              invalid={!!errors.department}
              invalidText={errors.department?.message}
            />
          </div>

          <Select
            id="reason"
            labelText="Причина проведения"
            defaultValue={values.reason}
            readOnly={readOnly}
            {...register('reason')}
          >
            {$catalog.healthCheckReason.map((x: any) => (
              <SelectItem key={x.value} text={x.shortName} value={x.value} />
            ))}
          </Select>
          <MonthSelect
            labelText="Период проведения"
            id="startDate"
            name="startDate"
            readOnly={readOnly}
            invalid={!!errors.startDate}
            onChange={(d: Date) => {
              setValue('startDate', d)
            }}
            invalidText={<>{errors.startDate?.message}</>}
            defaultValue={values.startDate}
            value={values.startDate}
          />
          <FormEditLink readOnly={readOnly} initOpen={!id} value={values.link?.linkText ?? ''}>
            <TextInput
              id="link"
              labelText="Ссылка"
              defaultValue={values.link?.linkText}
              invalid={!!errors.link?.linkText}
              invalidText={<>{errors.link?.linkText?.message}</>}
              {...register('link.linkText')}
            />
          </FormEditLink>
        </Stack>
        <HealthCheckIndexes
          value={values.indexList}
          setValue={setValue}
          readOnly={readOnly}
          errors={errors.indexList ?? {}}
        />
      </form>
    </WithLoaderWrapper>
  )
}

export default HealthCheckFormFields
