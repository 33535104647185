import { useMemo, useState } from 'react'

import dayjs from 'dayjs'
import { toJS } from 'mobx'

import { useLoader } from '@src/library/utils/hooks'

import { formatDate } from '@helpers/date'
import { useRouterSearchParams } from '@helpers/router'

import { $catalog } from '@library/providers/StoreProvider'

import { personService } from '@services'

export interface ITaskStatusFilterState {
  statusesList: any[]
  selectedStatuses: any[]
  setSelectedStatuses: Function
}

export const useTaskStatusFilter = () => {
  const defaultSelectedValues: string[] = ['ToDo', 'InProgress']
  const statusesList = useMemo(() => toJS($catalog.taskStatus), [])

  const searchParams = useRouterSearchParams()

  const fromParams = searchParams.get('taskStatuses') ?? defaultSelectedValues

  const selectedValues = _.isArray(fromParams) ? fromParams : [fromParams]

  const selectedStatuses = useMemo(
    () => statusesList.filter((x) => selectedValues.includes(x.value)),
    [selectedValues.join('^')],
  )

  const setSelectedStatuses = (selection: any) => {
    const params = selection.map((x: any) => x.value)
    searchParams.set('taskStatuses', params)
  }

  return {
    statusesList,
    selectedStatuses,
    setSelectedStatuses,
  }
}

export interface ITaskPeriodFilterState {
  startDate: Date | undefined
  setStartDate: Function
  endDate: Date | undefined
  setEndDate: Function
}

export const useTaskPeriodFilter = () => {
  const searchParams = useRouterSearchParams()

  const d1 = dayjs(searchParams.get('startDate'))
  const d2 = dayjs(searchParams.get('endDate'))

  const dd1 = d1.isValid() ? d1.toDate() : undefined
  const dd2 = d2.isValid() ? d2.toDate() : undefined

  const startDate = useMemo(() => dd1, [JSON.stringify(dd1)])
  const endDate = useMemo(() => dd2, [JSON.stringify(dd2)])

  function buildSetter(name: string) {
    return function (d: Date) {
      searchParams.set(name, formatDate(d, { format: 'isoDate' }))
    }
  }

  return {
    startDate,
    setStartDate: buildSetter('startDate'),
    endDate,
    setEndDate: buildSetter('endDate'),
  }
}

export const useTaskAuthorFilter = () => {
  const searchParams = useRouterSearchParams()
  const [authors, setAuthors] = useState<any[]>([])

  const isAuthorsLoading = useLoader(async () => {
    const resPers = await personService.fetch(undefined, undefined)

    if (resPers.isSuccess && resPers.data?.data) {
      const res = resPers.data?.data?.concat({ nickName: 'hcm_system' })
      const data = _.orderBy(res, 'nickName', 'asc')

      const mapped = data.map((x, index) => ({
        key: [index, x.nickName].join('_'),
        text: x.nickName,
        value: x.nickName,
        disabled: false,
      }))
      setAuthors(mapped)
    }
  }, [])
  const authorParam = searchParams.get('author')

  const author = useMemo(() => (authorParam === null ? undefined : authorParam), [authorParam])

  function setAuthor(author: string) {
    if (author) {
      searchParams.set('author', author)
    } else {
      searchParams.remove('author')
    }
  }

  return {
    authors,
    isAuthorsLoading,
    author,
    setAuthor,
  }
}
