import React, { FC, useMemo } from 'react'

import { observer } from 'mobx-react-lite'

import { formatDate } from '@helpers/date'

import { Comment } from '@services/models/task'

import styles from './CommentsTab.module.scss'

type IProps = {
  comments: Comment[]
}

const CommentsTab: FC<IProps> = ({ comments: data = [] }) => {
  const preparedList = useMemo(() => {
    return _.orderBy(data, 'commentDate', 'desc')
  }, [data])

  const highlightMentions = (text?: string) => {
    const mentionRegex = /@\[(.+?)\]\(.+?\)/g

    return text
      ? text.replace(mentionRegex, (match, p1) => {
          return `<span style="color: blue;">${p1}</span>`
        })
      : ''
  }

  return (
    <>
      <div className={styles.wrapper}>
        {preparedList.map((item: Comment, index: number) => (
          <div key={[item.id, item.author, index].join('_')} className={styles.item}>
            <div className={styles.header}>
              <div className={styles.author}>{item.author}</div>
              <div className={styles.date}>
                {formatDate(item.commentDate, { format: 'dateTimeShort' })}
              </div>
            </div>
            <div className={styles.content}>
              <div dangerouslySetInnerHTML={{ __html: highlightMentions(item?.commentText) }} />
            </div>
          </div>
        ))}

        {preparedList.length === 0 && <div>Комментариев пока нет</div>}
      </div>
    </>
  )
}

export default observer(CommentsTab)
