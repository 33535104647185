import React, { FC } from 'react'

import { PopoverAlignment } from '@carbon/react/lib/components/Popover'
import { observer } from 'mobx-react-lite'

import { $catalog, $loader } from '@library/providers/StoreProvider'
import EditStatus from '@library/ui/editStatus/EditStatus'

import { humanTaskService } from '@services'
import { Task } from '@services/models/task'

type IProps = {
  task: Task
  align?: PopoverAlignment
  disabled?: boolean
  silent?: boolean
}

const EditTaskStatus: FC<IProps> = ({
  task,
  align = 'bottom',
  disabled = false,
  silent = true,
}) => {
  const loaderName = silent ? 'edit-task-status-silent' : 'edit-task-status'

  const handleOnSelect = $loader.registerHandler(loaderName, async (item) => {
    const response = await humanTaskService.updateTaskStatus({
      id: task.id!,
      code: item.originalId,
    })

    return response.isSuccess
  })

  return (
    <EditStatus
      items={$catalog.taskStatus.map((x) => ({ value: x.value, text: x.shortName }))}
      value={task.status!}
      onChange={handleOnSelect}
      colorMapping={{
        todo: 'cool-gray',
        inprogress: 'blue',
        done: 'green',
      }}
      align={align}
      disabled={disabled}
    />
  )
}

export default observer(EditTaskStatus)
