import React, { FC, useEffect, useState } from 'react'

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@carbon/react'
import { observer } from 'mobx-react-lite'

import { AddCommentForm, CommentsTab } from '@src/modules/comments'

import InlineTabFormWrapper from '@library/layouts/tabs/InlineTabFormWrapper'
import { $user } from '@library/providers/StoreProvider'
import { formErrors } from '@library/utils/constants'
import { showErrorAlert } from '@library/utils/toast'

import { devPlanService } from '@services'

import styles from './PersonalEvolutionPlanFormTab.module.scss'

type IProps = {
  id: string
}
const PEP_ADD_COMMENT_FORM = 'pep-add-comment-form'

const PersonalEvolutionPlanFormTab: FC<IProps> = ({ id }) => {
  const [comments, setComments] = useState<any[]>([])

  const [selectedIndex, setSelectedIndex] = useState(0)

  async function loadData() {
    const response = await devPlanService.getPepComments({ id })

    if (response.isSuccess) {
      const data = response.data || []
      setComments(data)
    }
  }

  async function onCommentAdd(data: any) {
    const resAdd = await devPlanService.addPepComment({ id, commentText: data.comment })

    if (resAdd.isSuccess) {
      setComments((comments) => [
        {
          author: $user.loggedInUser.preferredUsername,
          commentDate: new Date(),
          commentText: data.comment,
        },
        ...comments,
      ])
    } else {
      showErrorAlert(formErrors.somethingWentWrong)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <Tabs defaultSelectedIndex={selectedIndex} onChange={(x) => setSelectedIndex(x.selectedIndex)}>
      <TabList
        aria-label="list-of-comments"
        contained={true}
        fullWidth={true}
        className={styles.tabs}
      >
        <Tab>Комментарии ({comments.length})</Tab>
      </TabList>
      <InlineTabFormWrapper>
        {selectedIndex === 0 && (
          <AddCommentForm formId={PEP_ADD_COMMENT_FORM} onCommentAdd={onCommentAdd} />
        )}
      </InlineTabFormWrapper>

      <TabPanels>
        {selectedIndex === 0 && (
          <TabPanel className="p-0">
            <CommentsTab comments={comments} />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  )
}

export default observer(PersonalEvolutionPlanFormTab)
