import { useMemo, useState } from 'react'

import { toJS } from 'mobx'

import { IFeedbackStatus } from '@src/modules/catalog/model/catalogStore'

import { $catalog } from '@library/providers/StoreProvider'

export const useStatusFilter = () => {
  const optionsStatus = useMemo(() => toJS($catalog.feedbackStatus), [$catalog.feedbackStatus])
  const [statuses, setStatuses] = useState<IFeedbackStatus[]>([])

  return {
    statuses,
    setStatuses,
    optionsStatus,
  }
}
