import _ from 'lodash'
import { action, makeAutoObservable, runInAction } from 'mobx'

import { generateId } from '@helpers/other'

export interface IModalItemCore {
  id: string
  templateName: string
  template: any
  isVisible: boolean
  multi: boolean
  show: () => void
  onShow?: () => void
  hide: () => void
  onHide?: () => void
  forceHide: () => void
  dropAll: () => void
}

export interface IModalItem {
  _core: IModalItemCore

  [key: string]: any
}

class ModalStore {
  current: IModalItem[] = []
  items: IModalItem[] = []

  constructor() {
    makeAutoObservable(this)
  }

  add(template: any, { multi = true, uniq = true, ...props }: any = {}) {
    let id = generateId()
    let passedProps: any = {}

    _.forEach(_.cloneDeep(props), (value, key) => {
      passedProps[key] = _.isFunction(value) ? action(value) : value
    })

    const templateName = template.type?.displayName || template.displayName || template.name

    if (uniq && !_.isEmpty(templateName) && this.findByTemplateName(templateName)) {
      return false
    }

    let result: IModalItem = {
      _core: {
        id,
        templateName,
        template,
        isVisible: false,
        multi,
        show: action(() => this.show(id)),
        hide: action(() => this.hide(id)),
        forceHide: action(() => this.forceHide(id)),
        dropAll: action(() => this.dropAll()),
      },
      ...passedProps,
    }

    if (multi) {
      this.items = [result, ...this.items]
    } else {
      this.items = [...this.items, result]
    }

    this.setNextCurrent()

    return result
  }

  findById(id: string) {
    return this.current.find((x) => x._core.id === id)
  }

  findByTemplateName(name: string) {
    return _.find(this.current, (x) => x._core.templateName === name)
  }

  setNextCurrent() {
    if (this.items.length && (_.isEmpty(this.current) || this.items[0]._core.multi)) {
      this.current = [...this.current, this.items.splice(0, 1)[0]]
    }
  }

  show(id: string) {
    let current = this.findById(id)

    if (current) {
      setTimeout(() => {
        runInAction(() => {
          if (_.isFunction(current!._core.onShow)) {
            current!._core.onShow()
          }

          current!._core.isVisible = true
        })
      })
    }
  }

  hide(id: string) {
    let current = this.findById(id)

    if (_.isFunction(current!._core.onHide)) {
      current!._core.onHide()
    }

    current!._core.isVisible = false
  }

  remove(id: string) {
    if (this.current.length) {
      this.current = this.current.filter((x) => x._core.id !== id)
      this.setNextCurrent()
    }
  }

  forceHide(id: string) {
    let current = this.findById(id)

    if (current) {
      this.remove(id)
    }
  }

  dropAll() {
    this.current.forEach((x) => (x._core.isVisible = false))
  }
}

const store = new ModalStore()
export default store
