import React, { useState } from 'react'

import { Close, Menu } from '@carbon/icons-react'
import { observer } from 'mobx-react-lite'

import HeaderMenu from '@src/modules/navigation/ui/HeaderMenu'

import { $windowSize } from '@library/utils/windowSize'

import logo from '@assets/images/logo-white.png'

import { useNavConfig } from '../model/config/AppNavigation.config'
import styles from './AppNavigation.module.scss'

const AppNavigation = () => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false)
  const { mainMenuItems, secondMenuItems } = useNavConfig()

  return (
    <>
      <header className={styles.header}>
        {$windowSize.maxMD && (
          <>
            <div className={styles.burger} onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}>
              {!mobileMenuIsOpen ? <Menu size={24} /> : <Close size={24} />}
            </div>
            {mobileMenuIsOpen && (
              <nav className={styles.mobileNav}>
                <HeaderMenu menuItems={mainMenuItems} onClose={() => setMobileMenuIsOpen(false)} />
              </nav>
            )}
          </>
        )}
        <div className={styles.logo}>
          <img src={logo} alt="Хризантема" />
        </div>
        {$windowSize.minMD && (
          <nav className={styles.nav}>
            <HeaderMenu menuItems={mainMenuItems} />
          </nav>
        )}
        <nav className={styles.secondNav}>
          <HeaderMenu menuItems={secondMenuItems} />
        </nav>
      </header>
    </>
  )
}

export default observer(AppNavigation)
