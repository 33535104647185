import React, { FC, ReactNode } from 'react'

import { DatePicker } from '@carbon/react'
import { DatePickerTypes } from '@carbon/react/lib/components/DatePicker/DatePicker'
import { DateOption, Hook } from 'flatpickr/dist/types/options'

import { mustParseDate } from '@helpers/date'

interface HcmDatePickerProps {
  children: ReactNode
  datePickerType?: DatePickerTypes
  dateFormat?: string
  onChange?: Hook
  onClose?: Hook
  value?: string | number | Array<string | number | object> | object | undefined
  readOnly?: boolean | [] | any | undefined
  minDate?: DateOption
  maxDate?: DateOption
  invalid?: boolean
  invalidText?: ReactNode
  className?: string
}

function parseDate(dateString: string) {
  const v = mustParseDate(dateString)

  return !v ? false : v
}

const HcmDatePicker: FC<HcmDatePickerProps> = (props) => {
  //preconfigured props only
  const { datePickerType = 'single', dateFormat = 'd.m.Y', children } = props

  return (
    <DatePicker
      {...props}
      datePickerType={datePickerType}
      dateFormat={dateFormat}
      parseDate={parseDate}
      locale={'ru'}
    >
      {children}
    </DatePicker>
  )
}

export default HcmDatePicker
