import { makeAutoObservable } from 'mobx'

import { queryClient } from '@src/library/utils/queryClient'
import { devPlanService } from '@src/services'
import { JobProfilesList } from '@src/services/models/health-check'

import { MobxQuery } from '@library/utils/mobx-react-query/mobx-query'

// import { MobxMutation } from '@library/utils/mobx-react-query/mobx-mutation'

export const GET_JOB_PROFILES_KEY = 'job-profiles'

class JobProfilesStore {
  query = ''

  jobProfilesQuery = new MobxQuery<JobProfilesList>(
    () => ({
      queryKey: [GET_JOB_PROFILES_KEY],
      queryFn: async () => {
        const response = await devPlanService.getJobProfiles()

        if (response.isSuccess && response?.data?.data) {
          return response.data
        }

        throw new Error()
      },
    }),
    queryClient,
  )

  constructor() {
    makeAutoObservable(this)
  }

  setQuery(value: string) {
    this.query = value
  }

  get filteredJobProfiles() {
    return this.jobProfilesQuery.result.data?.data?.filter(
      (item) => item?.name?.toLowerCase().includes(this.query.toLowerCase()),
    )
  }
}

export const jobProfilesStore = new JobProfilesStore()
