import React from 'react'
import { Outlet } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { useDepartmentFilter } from '@src/library/utils/hooks'

import PageTabs, { PageTabItem } from '@library/ui/pageTabs/PageTabs'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

const routesConfig: PageTabItem[] = [
  {
    label: 'Сотрудники',
    route: 'persons',
  },
  {
    label: 'Задачи',
    route: 'tasks',
  },
]

export function getAvailableEmployeeTabs() {
  return routesConfig.filter((x) => !x.condition || x.condition())
}

const MyEmployeesPage = () => {
  const isLoading = useDepartmentFilter()

  return (
    <>
      <PageTabs tabs={getAvailableEmployeeTabs()} />
      <WithLoaderWrapper isLoading={isLoading}>
        <Outlet />
      </WithLoaderWrapper>
    </>
  )
}

export default observer(MyEmployeesPage)
