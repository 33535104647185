import { action, makeAutoObservable, runInAction } from 'mobx'

import { generateId } from '@helpers/other'

import { $root as rootStore } from '@library/providers/StoreProvider'

export interface IRegistry {
  [action: string]: {
    isLoading: boolean
  }
}

export type ICallback = (...args: any[]) => any

export type ISubscribeType = 'beforeStart' | 'beforeFinish' | 'afterFinish'

export interface ISubscribers {
  [action: string]: Array<{
    id: string
    callback: ICallback
    type: ISubscribeType
  }>
}

export interface ILoaderStoreClass {
  activeRequests: number
  registry: IRegistry
  isLoading: boolean
  increaseRequests: () => void
  decreaseRequests: () => void
  registerHandler: (id: string, callback: ICallback, eventPayloadProvider?: Function) => Function
  isRunHandler: (id: string, useGlobal?: boolean) => boolean
  subscribers: ISubscribers
  subscribe: (handler: string | string[], callback: ICallback, type?: ISubscribeType) => any
  unsubscribe: (handler: string | string[], subscribeId: string | string[]) => any
}

class LoaderStore implements ILoaderStoreClass {
  $root

  constructor(store: typeof rootStore) {
    makeAutoObservable(this)
    this.$root = store
  }

  activeRequests = 0
  registry: IRegistry = {}
  subscribers: ISubscribers = {}

  get isLoading(): boolean {
    return !!this.activeRequests
  }

  increaseRequests(): void {
    this.activeRequests++
  }

  decreaseRequests(): void {
    if (this.activeRequests > 0) {
      setTimeout(() => runInAction(() => this.activeRequests--), 150)
    }
  }

  registerHandler(id: string, callback: ICallback, eventPayloadProvider?: any): any {
    return async (...args: any[]): Promise<any> => {
      const { beforeStart, beforeFinish, afterFinish } = this.getSubscribesByHandlerId(id)
      runInAction(() => (this.registry[id] = { isLoading: true }))

      if (beforeStart.length) {
        await Promise.all(beforeStart.map((x: any) => x.callback()))
      }

      const result: any = await callback(...args)

      if (beforeFinish.length) {
        await Promise.all(beforeFinish.map((x: any) => x.callback()))
      }

      runInAction(() => (this.registry[id] = { isLoading: false }))

      const eventPayload = eventPayloadProvider ? eventPayloadProvider(...args) : undefined

      if (afterFinish.length) {
        afterFinish.forEach((x: any) => x.callback(eventPayload))
      }

      return result
    }
  }

  isRunHandler(id: string, useGlobal: boolean = false): boolean {
    const flag = !!this.registry[id]?.isLoading

    return useGlobal ? flag && this.isLoading : flag
  }

  subscribe(
    handler: string | string[],
    callback: ICallback,
    type: ISubscribeType = 'beforeFinish',
  ): string | string[] {
    const handlers = _.isArray(handler) ? handler : [handler]
    const ids: string[] = []

    handlers.forEach((x) => {
      const item = { id: generateId(), callback: action(callback), type }
      ids.push(item.id)

      if (this.subscribers[x]) {
        this.subscribers[x].push(item)
      } else {
        this.subscribers[x] = [item]
      }
    })

    return _.isArray(handler) ? ids : ids[0]
  }

  unsubscribe(handler: string | string[], subscribeId?: string | string[]) {
    const handlers = _.isArray(handler) ? handler : [handler]
    const ids = _.isArray(subscribeId) ? subscribeId : [subscribeId].filter((x) => x)

    handlers.forEach((x) => {
      if (this.subscribers[x]) {
        if (ids.length) {
          let filtered = this.subscribers[x].filter((x) => !ids.includes(x.id))

          if (filtered.length) {
            this.subscribers[x] = filtered
          } else {
            delete this.subscribers[x]
          }
        } else {
          delete this.subscribers[x]
        }
      }
    })
  }

  getSubscribesByHandlerId(handlerId: string) {
    let result: { [key: string]: any } = {
      beforeStart: [],
      beforeFinish: [],
      afterFinish: [],
    }

    if (this.subscribers[handlerId]) {
      result.beforeStart = this.subscribers[handlerId].filter((x) => x.type === 'beforeStart')
      result.beforeFinish = this.subscribers[handlerId].filter((x) => x.type === 'beforeFinish')
      result.afterFinish = this.subscribers[handlerId].filter((x) => x.type === 'afterFinish')
    }

    return result
  }
}

export default LoaderStore
