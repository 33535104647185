import React, { FC, useState } from 'react'

import { Checkbox, CheckboxCheckedFilled, CheckboxIndeterminate } from '@carbon/icons-react'
import { Button, ButtonSet, unstable_FeatureFlags as FeatureFlags } from '@carbon/react'

/* @ts-ignore */
import { TreeNode, TreeView } from '@carbon/react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { RenderTree } from '@src/modules/globalFilter/model/appFilterStore'

import { $filter } from '@library/providers/StoreProvider'
import Title from '@library/ui/title/Title'
import { ModalBase, ModalBody, ModalFooter, ModalHeader } from '@library/utils/modals/index'
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store'

interface DepartmentsModalProps {
  onSelect: Function
  selected?: string[]
  _core: IModalItemCore
  singleValue?: boolean
}

const DepartmentsModal: FC<DepartmentsModalProps> = ({
  onSelect,
  selected: selectedByDefault,
  _core,
  singleValue,
}) => {
  const [selected, setSelected] = useState<string[]>(toJS(selectedByDefault) ?? [])

  const renderTree = (node: RenderTree, { depth = 1, defaultIsExpanded = false } = {}) => {
    const children = node.children?.map((x) => renderTree(x, { depth: depth + 1 }))

    const isSelected = (node.code === 'root' && !selected.length) || selected.includes(node.code)
    const isPartiallySelected = !!children?.some(
      (x) => x.isPartiallySelected || x.isSelected,
    ) as boolean

    // Если требуется разворачивать до уже выбранных элементов
    // defaultIsExpanded = defaultIsExpanded || isPartiallySelected || depth <= 1
    defaultIsExpanded = defaultIsExpanded || depth <= 1

    let renderIcon = Checkbox

    if (isSelected) {
      renderIcon = CheckboxCheckedFilled
    } else if (isPartiallySelected) {
      renderIcon = CheckboxIndeterminate
    }

    const NodeComponent = (
      <TreeNode
        key={node.code}
        id={node.code}
        value={node.code}
        label={node.name}
        renderIcon={renderIcon}
        defaultIsExpanded={defaultIsExpanded}
      >
        {children?.map((x) => x.NodeComponent)}
      </TreeNode>
    )

    return {
      node,
      isSelected,
      isPartiallySelected,
      defaultIsExpanded,
      NodeComponent,
    }
  }

  function handleSelect(arr: string[]) {
    const value = arr[0]
    let result = [] as string[]

    if (value === 'root') {
      return setSelected([])
    }

    if (singleValue) {
      const foundIndex = selected.indexOf(value)

      if (foundIndex === 0) {
        result.splice(foundIndex, 1)
      } else {
        result.push(value)
      }
    } else {
      result = [...selected]
      const foundIndex = result.indexOf(value)

      if (foundIndex >= 0) {
        result.splice(foundIndex, 1)
      } else {
        result.push(value)
      }
    }

    setSelected(result)
  }

  function handleSubmit() {
    onSelect && onSelect(selected)
    _core.hide()
  }

  function handleCancel() {
    _core.hide()
  }

  const departmentTree = $filter.departmentTree

  return (
    <ModalBase>
      <ModalHeader>
        <Title size="h2">Выберите подразделение</Title>
      </ModalHeader>
      <ModalBody>
        <FeatureFlags flags={{ 'enable-treeview-controllable': true }}>
          <TreeView onSelect={handleSelect} label={''} hideLabel={true}>
            {
              renderTree({
                code: 'root',
                name: 'Все подразделения',
                children: departmentTree,
              }).NodeComponent
            }
          </TreeView>
        </FeatureFlags>
      </ModalBody>
      <ModalFooter className={'mt-10'}>
        <ButtonSet>
          <Button kind="tertiary" onClick={handleCancel}>
            Отмена
          </Button>
          <Button onClick={handleSubmit}>Выбрать</Button>
        </ButtonSet>
      </ModalFooter>
    </ModalBase>
  )
}

export default observer(DepartmentsModal)
