import React from 'react'
import { QueryClientProvider } from 'react-query'
import { RouterProvider } from 'react-router-dom'

import { useClassNameByOS } from '@src/library/utils/hooks'

import AppAuthProvider from '@library/providers/AppAuthProvider'
import { ErrorBoundary } from '@library/providers/ErrorBoundary'
import { StoreProvider } from '@library/providers/StoreProvider'
import { routerConfig } from '@library/router'
import { useWindowSize } from '@library/utils/windowSize'

import './App.scss'
import { queryClient } from './library/utils/queryClient'

const App = () => {
  useWindowSize()
  useClassNameByOS()

  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider>
        <ErrorBoundary>
          <AppAuthProvider>
            <RouterProvider router={routerConfig} />
          </AppAuthProvider>
        </ErrorBoundary>
      </StoreProvider>
    </QueryClientProvider>
  )
}

export default App
