import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Button,
  ButtonSet,
  Column,
  ComboBox,
  DatePickerInput,
  Row,
  Select,
  SelectItem,
  Stack,
  TextInput,
} from '@carbon/react'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import { Hook } from 'flatpickr/dist/types/options'
import { observer } from 'mobx-react-lite'
import { z } from 'zod'

import { useLoader } from '@src/library/utils/hooks'
import filterStyles from '@src/modules/filters/ui/styles/Filters.module.scss'

import { $cabinet, $catalog, $user } from '@library/providers/StoreProvider'
import AuthorAndCreateDate from '@library/ui/author-and-createdate/AuthorAndCreateDate'
import HcmDatePicker from '@library/ui/datePicker/HcmDatePicker'
import FormEditLink from '@library/ui/formEditLink/FormEditLink'
import Title from '@library/ui/title/Title'
import { formErrors } from '@library/utils/constants'
import { ModalBase, ModalBody, ModalFooter, ModalHeader } from '@library/utils/modals'
import { IModalItemCore } from '@library/utils/modals/ModalViewer.store'
import { showErrorAlert } from '@library/utils/toast'

import { devPlanService, personService } from '@services'

import { FilterItem } from '../../filters/model/hooks/usePersonsFilter'
import PersonalEvolutionPlanFormTab from './PersonalEvolutionPlanFormTab'

type IProps = {
  id?: string
  afterSubmit?: Function
  allowPersonSelect?: boolean
  _core: IModalItemCore
}

const PERSONAL_EVOLUTION_PLAN_CREATE_UPDATE_FORM = 'personal-evolution-plan-create-update-form'

const schema = z
  .object({
    startDate: z.date({
      invalid_type_error: formErrors.invalidValue,
      required_error: formErrors.required,
    }),
    endDate: z.date({
      invalid_type_error: formErrors.invalidValue,
      required_error: formErrors.required,
    }),
    person: z.string().min(1, { message: formErrors.required }),
    fileType: z.string().min(1, { message: formErrors.required }),
    link: z.string().min(1).url({ message: 'Неправильный формат ссылки' }),
  })
  .superRefine((schema, ctx) => {
    if (schema.endDate <= schema.startDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['endDate'],
        message: formErrors.invalidPeriod,
      })
    }
  })

const PersonalEvolutionPlanFormModal: FC<IProps> = ({
  id,
  _core,
  afterSubmit,
  allowPersonSelect = false,
}) => {
  const [personList, setPersonsList] = useState<FilterItem[]>([])

  const isNew = !id
  const canEdit = $user.hasPerm('PERM_IPR_EDIT') || $user.hasPerm('PERM_IPR_ADD')

  /*
   * Form controller
   */
  const defaultValues = {
    startDate: '',
    endDate: '',
    createDate: undefined,
    author: undefined,
    code: undefined,
    person: allowPersonSelect ? '' : $cabinet.selectedLogin,
    link: '',
    fileType: undefined,
  }

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  })

  /*
   * Data loading
   */
  const isPersonalEvolutionPlanLoading = useLoader(async () => {
    if (isNew) {
      return
    }

    const response = await devPlanService.fetchPersonalEvolutionPlan(id!)

    if (response.isSuccess && response.data) {
      let data = response.data

      if (data.startDate) {
        data.startDate = dayjs(data.startDate).toDate()
      }

      if (data.endDate) {
        data.endDate = dayjs(data.endDate).toDate()
      }

      const preparedData = _.mapValues(defaultValues, (x, key) => {
        if (_.isNil(data[key])) {
          return x
        }

        return data[key]
      })

      reset(preparedData)
    }
  })
  const isPersonsLoading = useLoader(async () => {
    const manager = $user.hasPerm('PERM_IPR_MANAGER')
      ? $user.loggedInUser.preferredUsername
      : undefined

    const response = await personService.fetch(manager, undefined, manager)

    if (response.isSuccess && response.data?.data) {
      const data = _.orderBy(response.data?.data, 'nickName', 'asc')
      const mapped = data.map((user: any) => ({
        key: [user.id, user.nickName].join('_'),
        text: user.nickName!,
        value: user.nickName!,
      }))
      setPersonsList(mapped)
    }
  })

  const isLoading = isPersonalEvolutionPlanLoading || isPersonsLoading

  /*
   * Prepared values
   */
  const values = watch()

  useEffect(() => {
    if (!isLoading) {
      // console.log('values', values)
      // console.log('errors', errors)
    }
  }, [isPersonalEvolutionPlanLoading, isPersonsLoading, values])

  /*
   * Field handlers
   */
  const onStartDateChange: Hook = (dates: any) => {
    setValue('startDate', dates[0])
  }

  const onEndDateChange: Hook = (dates: any) => {
    setValue('endDate', dates[0])
  }

  /*
   * Submit handler
   */

  const onSubmit = async () => {
    let response

    if (isNew) {
      response = await devPlanService.createPersonalEvolutionPlan(values)
    } else {
      response = await devPlanService.updatePersonalEvolutionPlan({
        ...values,
        id,
      })
    }

    if (response.isSuccess) {
      afterSubmit && afterSubmit()
      _core.hide()
    } else {
      showErrorAlert(formErrors.somethingWentWrong)
    }
  }

  return (
    <ModalBase size={isNew ? 'md' : 'xxl'} onShow={() => {}} onHide={() => {}}>
      <ModalHeader className="mb-20">
        <Title size="h2">{id ? `Редактирование ИПР${id}` : 'Создание ИПР'}</Title>
      </ModalHeader>
      <ModalBody isLoading={isLoading} className="pb-20">
        <Row>
          <Column lg={isNew ? 16 : 8}>
            {!isNew && (
              <AuthorAndCreateDate author={values.author} createDate={values.createDate} />
            )}
            <form
              id={PERSONAL_EVOLUTION_PLAN_CREATE_UPDATE_FORM}
              noValidate={true}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Stack gap={6}>
                <ComboBox
                  /* @ts-ignore */
                  id="person"
                  titleText="Сотрудник"
                  items={personList}
                  itemToString={(item: any) => (item ? item.text : '')}
                  onChange={(item: any) => setValue('person', item?.selectedItem?.text)}
                  selectedItem={
                    !!values.person && {
                      text: values.person,
                      value: values.person,
                    }
                  }
                  disabled={isPersonsLoading}
                  className={filterStyles.comboBox}
                  invalid={!!errors.person}
                  invalidText={<>{errors.person?.message}</>}
                  readOnly={!(allowPersonSelect && canEdit)}
                  downshiftProps={!(allowPersonSelect && canEdit) ? { isOpen: false } : undefined}
                />
                <Select
                  id="fileType"
                  labelText="Формат хранения ИПР"
                  {...register('fileType')}
                  invalid={!!errors.fileType}
                  invalidText={<>{errors.fileType?.message}</>}
                  readOnly={!canEdit}
                >
                  {$catalog.fileType.map((x: any, index: number) => (
                    <SelectItem
                      key={[index, x.value].join('_')}
                      text={x.shortName}
                      value={x.value}
                    />
                  ))}
                </Select>
                <HcmDatePicker
                  onChange={onStartDateChange}
                  onClose={onStartDateChange}
                  value={values.startDate}
                  readOnly={!canEdit}
                >
                  <DatePickerInput
                    labelText="Период C"
                    placeholder="dd.mm.yyyy"
                    id="startDate"
                    invalid={!!errors.startDate}
                    invalidText={<>{errors.startDate?.message}</>}
                    readOnly={!canEdit}
                  />
                </HcmDatePicker>
                <HcmDatePicker
                  onChange={onEndDateChange}
                  onClose={onEndDateChange}
                  value={values.endDate}
                  readOnly={!canEdit}
                >
                  <DatePickerInput
                    labelText="По"
                    placeholder="dd.mm.yyyy"
                    id="endDate"
                    invalid={!!errors.endDate}
                    invalidText={<>{errors.endDate?.message}</>}
                    readOnly={!canEdit}
                  />
                </HcmDatePicker>
                <FormEditLink value={values.link} initOpen={isNew} readOnly={!canEdit}>
                  <TextInput
                    id="link"
                    labelText="Ссылка на ИПР"
                    {...register('link')}
                    invalid={!!errors.link}
                    invalidText={<>{errors.link?.message}</>}
                    readOnly={!canEdit}
                  />
                </FormEditLink>
              </Stack>
            </form>
          </Column>
          {!isNew && (
            <Column lg={8} style={{ maxHeight: 500 }}>
              <PersonalEvolutionPlanFormTab id={id} />
            </Column>
          )}
        </Row>
      </ModalBody>
      {!isLoading && (
        <ModalFooter>
          <ButtonSet>
            {canEdit ? (
              <>
                <Button kind="tertiary" onClick={_core.hide}>
                  Отмена
                </Button>
                <Button form={PERSONAL_EVOLUTION_PLAN_CREATE_UPDATE_FORM} type="submit">
                  Сохранить
                </Button>
              </>
            ) : (
              <Button onClick={_core.hide}>Закрыть</Button>
            )}
          </ButtonSet>
        </ModalFooter>
      )}
    </ModalBase>
  )
}

export default observer(PersonalEvolutionPlanFormModal)
