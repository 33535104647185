import React, { type FC, useMemo } from 'react'
import { Bar } from 'react-chartjs-2'

import type { ChartData, ChartOptions } from 'chart.js'
import classNames from 'classnames'

import Title from '@library/ui/title/Title'

import { OnboardingAnalytics, OnboardingAnalyticsMetrics } from '@services/models/dashboard'

import { emptyPlaceHolderPlugin, wrapLabel } from '../../model/helpers/formatters'

interface IProps {
  analytics: OnboardingAnalytics[]
  title: string
  datasetLabel: string
  primaryColor: string
  dataKey: keyof OnboardingAnalyticsMetrics
  className?: string
}

const options: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        callback(value, index) {
          return wrapLabel(this.getLabelForValue(index))
        },
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
  },
}

const OnboardingsChartCount: FC<IProps> = ({
  analytics = [],
  dataKey,
  title,
  datasetLabel,
  primaryColor,
  className,
}) => {
  const data = useMemo(() => {
    const labels = analytics.map((x) => x.departmentName!)
    const datasetData = analytics.map((x) => x.metrics?.[dataKey])

    return {
      labels,
      datasets: [
        {
          label: datasetLabel,
          data: datasetData,
          backgroundColor: primaryColor,
          maxBarThickness: 40,
        },
      ],
    } as ChartData<'bar'>
  }, [analytics])

  return (
    <>
      <Title size={'h4'} className={classNames('mb-10')}>
        {title}
      </Title>
      <div className={className}>
        <Bar options={options} data={data} plugins={[...emptyPlaceHolderPlugin]} />
      </div>
    </>
  )
}

export default OnboardingsChartCount
