import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import {
  DataTable,
  PaginationNav,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react'
import { observer } from 'mobx-react-lite'

import { useLoader, usePagination } from '@src/library/utils/hooks'

import { $user } from '@library/providers/StoreProvider'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

import { personService } from '@services'
import { PersonCardForList } from '@services/models/person'

const EmployeesListPage = () => {
  const [personList, setPersonList] = useState<any>([])

  const {
    page,
    setPage,
    totalItems,
    setPagingCount,
    sortBy,
    sortOrder,
    handleHeaderClick,
    limit,
    offset,
  } = usePagination({ defaultSortBy: 'department', defaultSortOrder: 'desc' })

  const nav = useNavigate()

  const isLoading = useLoader(async () => {
    const response = await personService.fetch(
      undefined,
      undefined,
      $user.nickname,
      limit,
      offset,
      sortBy,
      sortOrder,
    )

    if (response.isSuccess) {
      setPersonList(response.data?.data ?? [])
      setPagingCount(response.data?.paging?.count ?? 0)
    }
  }, [sortBy, sortOrder, page])

  const headers = [
    { key: 'departmentName', header: 'Подразделение', isSortable: true },
    { key: 'nickName', header: 'Сотрудник', isSortable: true },
    { key: 'name', header: 'Имя', isSortable: true },
    { key: 'jobPosition', header: 'Должность', isSortable: true },
    { key: 'city', header: 'Город', isSortable: true },
  ]

  const rows = personList.map((x: PersonCardForList) => ({
    id: x.nickName,
    departmentName: x.department?.name,
    nickName: <Link to={'/user-profile/' + x.nickName}>{x.nickName}</Link>,
    name: x.name,
    jobPosition: x.jobPosition,
    city: x.city,
  }))

  return (
    <div className="mt-20">
      <DataTable headers={headers} rows={rows} isSortable={false}>
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps, getToolbarProps }) => {
          return (
            <TableContainer>
              <WithLoaderWrapper isLoading={isLoading} align="top">
                <Table {...getTableProps()}>
                  <TableHead>
                    <TableRow>
                      {headers.map((header: any) => (
                        <TableHeader
                          {...getHeaderProps({ header })}
                          {...getHeaderProps({ header })}
                          isSortable={header.isSortable}
                          onClick={() => handleHeaderClick(header.key)}
                          isSortHeader={sortBy === header.key}
                        >
                          {header.header}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row) => (
                      <TableRow
                        {...getRowProps({ row })}
                        onClick={(e) => {
                          nav('/user-profile/' + row.id)
                        }}
                      >
                        {row.cells.map((cell: any) => (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </WithLoaderWrapper>
            </TableContainer>
          )
        }}
      </DataTable>
      {
        <PaginationNav
          className="pagination"
          page={page}
          totalItems={totalItems}
          onChange={setPage}
        />
      }
    </div>
  )
}

export default observer(EmployeesListPage)
