import React, { FC } from 'react'

import { valueOrDash } from '@helpers/other'

import { Goal } from '@services/models/health-check'

import styles from './GPAccordionItem.module.scss'

interface IProps {
  goals: Goal[]
  planUrl: string
  performanceUrl?: string
}

const GPAccordionItem: FC<IProps> = ({ planUrl, performanceUrl, goals = [] }) => {
  return (
    <div className="row">
      <div className="col-8 col-lg-12">
        {goals.map((goal, goalIndex) => (
          <div key={goalIndex} className={styles.goal}>
            <div>
              <strong>Цель:</strong> {valueOrDash(goal.body)}
            </div>
            <div>
              <strong>Критерий завершенности:</strong> {valueOrDash(goal.completionCriteria)}
            </div>
          </div>
        ))}
      </div>
      <div className="col-4 col-lg-12">
        <div className="col-12 col-lg-12 text-right">
          {planUrl && (
            <a href={planUrl} target="_blank" rel="noreferrer">
              Посмотреть цели в Aiday
            </a>
          )}
        </div>
        <div className="col-12 col-lg-12 text-right mt-20">
          {performanceUrl && (
            <a href={performanceUrl} target="_blank" rel="noreferrer">
              Посмотреть оценку в Aiday
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default GPAccordionItem
