import { useMemo } from 'react'

import { PersonCardForList } from '@services/models/person'

import { ExtendedPersonCardForList, ExtendedPersonsObj } from './PersonHierarchyModal.types'

export const useExtendedPersonsObj = (personsList: PersonCardForList[]) => {
  return useMemo(() => {
    let result: ExtendedPersonsObj = {}

    personsList.forEach((item) => {
      let asManager: string[] = []
      let asFunctionalManager: string[] = []

      personsList.forEach((x) => {
        if (item.nickName === x.manager) {
          asManager.push(x.nickName!)
        }

        if (item.nickName === x.functionalManager) {
          asFunctionalManager.push(x.nickName!)
        }
      })

      asManager = _.orderBy(asManager, (x) => _.toLower(x), 'asc')
      asFunctionalManager = _.orderBy(asFunctionalManager, (x) => _.toLower(x), 'asc')

      result[item.nickName!] = { ...item, asManager, asFunctionalManager }
    })

    return result
  }, [personsList])
}

export const useBreadcrumb = (
  currentPerson: ExtendedPersonCardForList | undefined,
  extendedPersonsObj: ExtendedPersonsObj | undefined,
) => {
  return useMemo(() => {
    function mapBreadcrumb(acc: ExtendedPersonCardForList[]) {
      const found = extendedPersonsObj?.[acc[acc.length - 1]?.manager!]

      if (found) {
        acc.push(found)

        if (found.manager) {
          acc = mapBreadcrumb(acc)
        }
      }

      return acc
    }

    return currentPerson && extendedPersonsObj ? mapBreadcrumb([currentPerson]).reverse() : []
  }, [currentPerson])
}
