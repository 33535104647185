import React from 'react'

import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@carbon/react'

import { formatDate } from '@helpers/date'

import LayoutLkPageWrapper from '@library/layouts/LayoutLkPageWrapper'
import { useStore } from '@library/providers/StoreProvider'

const TransitionsPage = () => {
  const { $cabinet } = useStore()
  const transitionsList = $cabinet.data?.transitions?.data

  const headers = [
    { key: 'startDate', header: 'Дата начала работы' },
    { key: 'endDate', header: 'Дата окончания' },
    { key: 'department', header: 'Подразделение' },
    { key: 'jobPosition', header: 'Должность' },
    { key: 'reason', header: 'Причина перевода' },
  ]

  const rows = _.orderBy(transitionsList, 'startDate', 'desc').map((transition, index) => ({
    id: (index + 1).toString(),
    startDate: formatDate(transition?.startDate),
    endDate: formatDate(transition?.endDate),
    department: transition?.department,
    jobPosition: transition?.jobPosition,
    reason: transition?.reason,
  }))

  return (
    <LayoutLkPageWrapper isLoading={false}>
      <DataTable headers={headers} rows={rows} isSortable={false}>
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => {
          return (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })} onClick={() => {}}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow {...getRowProps({ row })} onClick={() => {}}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )
        }}
      </DataTable>
    </LayoutLkPageWrapper>
  )
}

export default TransitionsPage
