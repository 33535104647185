import { useLayoutEffect } from 'react'

import { $filter } from '@library/providers/StoreProvider'

export const useFirstDepartment = () => {
  useLayoutEffect(() => {
    if (
      !$filter.filterInitialized &&
      $filter.departmentTree.length &&
      !$filter.selectedCodes.length
    ) {
      $filter.SET_SELECTED_CODES([$filter.departmentTree[0].code])
    }
  }, [])
}
