import React, { FC, useEffect, useMemo } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Button, Tab, TabList, TabPanel, TabPanels, Tabs } from '@carbon/react'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import DepartmentSelect from '@src/modules/department/ui/DepartmentSelect'
import HealthCheckFormModal from '@src/modules/healthcheck/ui/HealthCheckFormModal'
import HealthChecksTab from '@src/modules/healthcheck/ui/HealthChecksTab'
import styles from '@src/modules/healthcheck/ui/HealthChecksTab.module.scss'

import { currentQuarter, formatQuarter } from '@helpers/date'
import { useRouterSearchParams } from '@helpers/router'

import PermissionsProvider from '@library/providers/PermissionsProvider'
import { $modal, $user } from '@library/providers/StoreProvider'

type HealthChecksPageProps = {}

const PERIOD_TAB_IDX = 4

const HealthChecksPage: FC<HealthChecksPageProps> = () => {
  function populateButtons() {
    const dates = []
    let qDate = dayjs(currentQuarter())

    for (let i = 0; i < 4; i++) {
      const d = qDate.subtract(i, 'quarter')
      dates.push(d.format('YYYY-MM-DD'))
    }

    return dates
  }

  const searchParams = useRouterSearchParams()
  const periodStartDate = searchParams.get('periodStartDate')
  const periodEndDate = searchParams.get('periodEndDate')
  const { qDate } = useParams()
  const buttons: string[] = populateButtons()

  const [selectedIndex, setSelectedIndex] = React.useState(getSelectedIndex())

  let [_, setSearchParams] = useSearchParams()

  const nav = useNavigate()

  function getSelectedIndex() {
    if (qDate) {
      const idx = buttons.indexOf(qDate)

      if (idx > -1) {
        return idx
      }
    } else if (periodStartDate || periodEndDate) {
      return PERIOD_TAB_IDX
    }

    return 0
  }

  useEffect(() => {
    setSelectedIndex(getSelectedIndex())
  }, [qDate, periodStartDate, periodEndDate])

  function handleAdd() {
    $modal.add(HealthCheckFormModal, { multi: true })
  }

  function handleSelection(x: any) {
    const selection = x.selectedIndex

    if (selection === PERIOD_TAB_IDX) {
      if (qDate) {
        //избавляемся от квартала
        nav('../../', { relative: 'path' })
      }

      setSearchParams({
        periodStartDate: dayjs().subtract(3, 'M').format('YYYY-MM-DD'),
        periodEndDate: dayjs().format('YYYY-MM-DD'),
      })

      setSelectedIndex(selection)
    } else {
      if (qDate) {
        nav(`../../q/${buttons[x.selectedIndex]}`, { relative: 'path' })
      } else {
        nav(`q/${buttons[x.selectedIndex]}`)
      }
    }
  }

  const startQDate = useMemo(() => new Date(buttons[selectedIndex]), [selectedIndex])

  return (
    <PermissionsProvider condition={() => !$user.isHrDev}>
      <div className={styles.header}>
        <DepartmentSelect />
        {$user.hasPerm('PERM_HEALTHCHECK_FULL') && (
          <Button size="lg" onClick={handleAdd}>
            Создать HC
          </Button>
        )}
      </div>

      <div>
        <Tabs selectedIndex={selectedIndex} onChange={handleSelection}>
          <TabList
            aria-label="Кварталы"
            activation="manual"
            fullWidth={true}
            className={'mt-10 mb-20'}
          >
            {buttons.map((label) => (
              <Tab key={label}>{`${formatQuarter(new Date(label))}`}</Tab>
            ))}
            <Tab>Период</Tab>
          </TabList>
          <TabPanels>
            {buttons.map((label, idx) => (
              <TabPanel key={idx} className={'p-0'}>
                {selectedIndex === idx && <HealthChecksTab startQDate={startQDate} />}
              </TabPanel>
            ))}
            <TabPanel className={'p-0'}>
              {selectedIndex === PERIOD_TAB_IDX && <HealthChecksTab showSelector={true} />}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </PermissionsProvider>
  )
}

export default HealthChecksPage
