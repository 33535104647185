import React from 'react'

import { $cabinet } from '@library/providers/StoreProvider'
import { EMPLOYEE, HRDEV } from '@library/utils/constants'

import { AbsencePage } from '../modules/absence'
import { EquipmentPage } from '../modules/equipment'
import { GoalsPerformancePage } from '../modules/goalsPerformance'
import { OnboardingsPage } from '../modules/onboardings'
import { PersonalEvaluationPlanPage } from '../modules/personalEvolutionPlan'
import { SkillReviewsPage } from '../modules/skillReviews'
import { TasksPage } from '../modules/tasks'
import { TransitionsPage } from '../modules/transitions'

export const nestedRoutes = (role: string) => {
  switch (role) {
    case EMPLOYEE:
      return [
        {
          label: 'Отсутствия',
          route: 'absence',
          element: <AbsencePage />,
          condition: () => $cabinet.canSeeAbsence,
        },
        {
          label: 'Оборудование',
          route: 'equipment',
          element: <EquipmentPage />,
          condition: () => $cabinet.canSeeEquipment,
        },
        {
          label: 'История перемещений',
          route: 'transitions',
          element: <TransitionsPage />,
          condition: () => $cabinet.canSeeTransitions,
        },
        {
          label: 'ИПР',
          route: 'ipr',
          element: <PersonalEvaluationPlanPage />,
          condition: () => $cabinet.canSeePersonalEvolution,
        },
        {
          label: 'Оценка компетенций',
          route: 'competence',
          element: <SkillReviewsPage />,
          condition: () => $cabinet.canSeeSkillReview,
        },
        {
          label: 'Адаптация',
          route: 'onboardings',
          element: <OnboardingsPage />,
          condition: () => $cabinet.canSeeOnboardings,
        },
        {
          label: 'Цели и результативность',
          route: 'goals',
          element: <GoalsPerformancePage />,
          condition: () => true,
        },
        {
          label: 'Задачи',
          route: 'tasks',
          element: <TasksPage />,
          condition: () => $cabinet.canSeeTasks,
        },
      ]

    case HRDEV:
      return [
        {
          label: 'Адаптация',
          route: 'onboardings',
          element: <OnboardingsPage />,
          condition: () => $cabinet.canSeeOnboardings,
        },
        {
          label: 'Цели и результативность',
          route: 'goals',
          element: <GoalsPerformancePage />,
          condition: () => true,
        },
        {
          label: 'Оценка компетенций',
          route: 'competence',
          element: <SkillReviewsPage />,
          condition: () => $cabinet.canSeeSkillReview,
        },
        {
          label: 'ИПР',
          route: 'ipr',
          element: <PersonalEvaluationPlanPage />,
          condition: () => $cabinet.canSeePersonalEvolution,
        },
        {
          label: 'Отсутствия',
          route: 'absence',
          element: <AbsencePage />,
          condition: () => $cabinet.canSeeAbsence,
        },
        {
          label: 'История перемещений',
          route: 'transitions',
          element: <TransitionsPage />,
          condition: () => $cabinet.canSeeTransitions,
        },

        {
          label: 'Оборудование',
          route: 'equipment',
          element: <EquipmentPage />,
          condition: () => $cabinet.canSeeEquipment,
        },
      ]

    default:
      return [
        {
          label: 'Задачи',
          route: 'tasks',
          element: <TasksPage />,
          condition: () => $cabinet.canSeeTasks,
        },

        {
          label: 'Адаптация',
          route: 'onboardings',
          element: <OnboardingsPage />,
          condition: () => $cabinet.canSeeOnboardings,
        },
        {
          label: 'Цели и результативность',
          route: 'goals',
          element: <GoalsPerformancePage />,
          condition: () => true,
        },
        {
          label: 'Оценка компетенций',
          route: 'competence',
          element: <SkillReviewsPage />,
          condition: () => $cabinet.canSeeSkillReview,
        },
        {
          label: 'ИПР',
          route: 'ipr',
          element: <PersonalEvaluationPlanPage />,
          condition: () => $cabinet.canSeePersonalEvolution,
        },
        {
          label: 'Отсутствия',
          route: 'absence',
          element: <AbsencePage />,
          condition: () => $cabinet.canSeeAbsence,
        },
        {
          label: 'История перемещений',
          route: 'transitions',
          element: <TransitionsPage />,
          condition: () => $cabinet.canSeeTransitions,
        },

        {
          label: 'Оборудование',
          route: 'equipment',
          element: <EquipmentPage />,
          condition: () => $cabinet.canSeeEquipment,
        },
      ]
  }
}
