import React, { FC, useMemo } from 'react'
import { Bar } from 'react-chartjs-2'

import 'chart.js/auto'
import { observer } from 'mobx-react-lite'

import { getRandomColor, UIColors } from '@library/ui/UIColors'

import {
  TaskAnalyticsDepartmentObjectMetrics,
  TaskAnalyticsPersonObjectMetrics,
} from '@services/models/dashboard'

type Props = {
  departmentsData?: TaskAnalyticsDepartmentObjectMetrics[]
  personsData?: TaskAnalyticsPersonObjectMetrics[]
  title: string
}

const HistogramChart: FC<Props> = ({ departmentsData, personsData, title }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right' as const,
      },
      title: {
        display: true,
        text: title,
        position: 'top' as const,
        color: '#000000',
        padding: 10,
        font: { size: 16 },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  }

  const performers = departmentsData?.length
    ? [...new Set(departmentsData?.map((item) => item.performer))]
    : personsData?.length
    ? [...new Set(personsData?.map((item) => item.performer))]
    : ['']
  const departmentNames = [...new Set(departmentsData?.map((item) => item.departmentObjectName))]
  const personsName = [...new Set(personsData?.map((item) => item.personObject))]

  const chartData = useMemo(() => {
    if (departmentsData) {
      return {
        labels: performers,
        datasets: departmentNames.map((department, index) => {
          return {
            label: department,
            data: performers?.map((performer) => {
              const items =
                departmentsData?.filter(
                  (d) => d.performer === performer && d.departmentObjectName === department,
                ) || []

              return items?.length > 0
                ? items.reduce((sum, item) => sum + (item.count ?? 0), 0)
                : null
            }),
            backgroundColor: index < UIColors.length ? UIColors[index] : getRandomColor(),
            barThickness: 10,
            skipNull: true,
          }
        }),
      }
    }

    if (personsData) {
      return {
        labels: performers,
        datasets: personsName.map((name, index) => {
          return {
            label: name,
            data: performers?.map((performer) => {
              const items =
                personsData?.filter((p) => p.performer === performer && p.personObject === name) ||
                []

              return items?.length > 0
                ? items.reduce((sum, item) => sum + (item.count ?? 0), 0)
                : null
            }),
            backgroundColor: index < UIColors.length ? UIColors[index] : getRandomColor(),
            barThickness: 10,
            skipNull: true,
          }
        }),
      }
    }

    return {
      labels: [''],
      datasets: [{ data: [0] }],
    }
  }, [departmentsData, personsData])

  return <Bar options={options} data={chartData} height={500} key={title} />
}

export default observer(HistogramChart)
