import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import AppNavigation from '@src/modules/navigation/ui/AppNavigation'

import { useStopInitialLoading } from '@library/layouts/layout.hook'
import { $user } from '@library/providers/StoreProvider'

import styles from './DefaultLayout.module.scss'

type DefaultLayoutProps = {
  showNavigation: boolean
}

const DefaultLayout: FC<DefaultLayoutProps> = ({ showNavigation = true }) => {
  useStopInitialLoading($user.isPermissionsLoaded)

  return (
    <div className={styles.wrapper}>
      {showNavigation && <AppNavigation />}
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default observer(DefaultLayout)
