import React, { useMemo, useState } from 'react'

import { Search, Tab, TabList, Tabs } from '@carbon/react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import { useInnerValue, useLoader } from '@src/library/utils/hooks'
import PersonHierarchyModal from '@src/modules/person/personHierarchyModal/ui/PersonHierarchyModal'
import StructureTree from '@src/modules/structureTree/StructureTree'
import {
  DepartmentTreeItem,
  StructureTreeNode,
} from '@src/modules/structureTree/StructureTree.types'
import {
  createSearchMap,
  departmentsAsTree,
  enrichDepartmentsTreeWithPersons,
  filterSearchMap,
  filterTreeData,
} from '@src/pages/reference/model/CompanyStructurePage.helpers'

import { useRouterSearchParams } from '@helpers/router'

import { $modal } from '@library/providers/StoreProvider'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

import { catalogService, personService } from '@services'
import { Department } from '@services/models/catalog'
import { PersonCardForList } from '@services/models/person'

import styles from './CompanyStructurePage.module.scss'

const CompanyStructurePage = () => {
  const searchParams = useRouterSearchParams()

  const [departments, setDepartments] = useState<Department[]>([])
  const [persons, setPersons] = useState<PersonCardForList[]>([])

  const [query, setQuery] = useInnerValue(decodeURIComponent(searchParams.get('search') || ''))

  const isDepsLoading = useLoader(async () => {
    //здесь нужны все подразделения
    const response = await catalogService.fetchDepartments(undefined, undefined)

    if (response.isSuccess && response.data[0]?.data) {
      setDepartments(response.data[0].data)
    }
  })

  const isPersLoading = useLoader(async () => {
    const resPers = await personService.fetch(undefined, undefined)

    if (resPers.isSuccess && resPers.data?.data) {
      setPersons(resPers.data.data)
    }
  })

  const isLoading = isPersLoading || isDepsLoading

  const departmentsTree = useMemo(() => {
    if (isLoading) {
      return []
    }

    const tree = departmentsAsTree(departments)

    return enrichDepartmentsTreeWithPersons(tree, persons) as DepartmentTreeItem[]
  }, [isLoading, departments, persons])

  const [selectedIndex, setSelectedIndex] = useState(0)

  const { treeData, searchMap } = useMemo(() => {
    let result: StructureTreeNode

    if (selectedIndex > 0) {
      result = departmentsTree[selectedIndex - 1]
    } else {
      result = {
        name: 'Все подразделения',
        code: 'root_all_departments',
        itemType: 'department',
        cardStyle: 'top',
        persons: [],
        children: departmentsTree,
      }
    }

    return { treeData: result, searchMap: createSearchMap(result) }
  }, [departmentsTree, selectedIndex])

  const filteredTreeData = useMemo(() => {
    if (query.trim() === '') {
      return treeData
    }

    const filteredSearchMap = filterSearchMap(searchMap, query)

    return filterTreeData(treeData, filteredSearchMap)
  }, [treeData, query])

  const handleSearch = _.debounce((e: any) => {
    setQuery(e.target.value)
    searchParams.set('search', encodeURIComponent(e.target.value) || null, { replace: true })
  }, 200)

  return (
    <WithLoaderWrapper isLoading={isLoading}>
      <div className={styles.pageWrapper}>
        <div className={classNames(styles.tabs, 'mb-20')}>
          <Tabs
            defaultSelectedIndex={selectedIndex}
            onChange={(x) => setSelectedIndex(x.selectedIndex)}
          >
            <TabList aria-label="strucrure-roots" fullWidth={true}>
              <Tab>Все подразделения</Tab>
              {departmentsTree.map((item) => (
                <Tab key={item.code}>{item.name}</Tab>
              ))}
            </TabList>
          </Tabs>
        </div>
        <Search
          size="lg"
          defaultValue={query}
          placeholder="Введите подразделение, имя, ник или должность"
          labelText="Поиск"
          closeButtonLabelText="Очистить"
          id="company-structure-search"
          onChange={handleSearch}
        />
        <div className={styles.contentWrapper}>
          {filteredTreeData && (
            <StructureTree
              data={filteredTreeData as StructureTreeNode}
              // onClick={(event: React.MouseEvent<HTMLElement>, nickName: string) => {
              //   event.stopPropagation()
              //   nav('/user-profile/' + nickName)
              // }}
              onClick={(event: React.MouseEvent<HTMLElement>, nickName: string) => {
                event.stopPropagation()
                $modal.add(PersonHierarchyModal, { initNickName: nickName, personsList: persons })
              }}
              depth={selectedIndex > 0 ? 2 : 1}
              searchQuery={query}
            />
          )}
        </div>
      </div>
    </WithLoaderWrapper>
  )
}

export default observer(CompanyStructurePage)
