import { useEffect } from 'react'
import { isMobile, isTablet } from 'react-device-detect'

import { makeAutoObservable } from 'mobx'

const s = {
  xs: 0,
  sm: 672, // 42rem
  md: 1056, // 66rem
  lg: 1312, // 82rem
  xl: 1584, // 99rem
}

const h = {
  xs: 0,
  sm: 360,
  md: 700,
  lg: 800,
  xl: 1080,
}

class WindowSizeStore {
  constructor() {
    makeAutoObservable(this)
  }

  width = window.innerWidth
  height = window.innerHeight

  UPDATE_SIZES() {
    this.width = window.innerWidth
    this.height = window.innerHeight
  }

  get isXS() {
    return this.width >= s.xs && this.width < s.sm
  }

  get isSM() {
    return this.width >= s.sm && this.width < s.md
  }

  get isMD() {
    return this.width >= s.md && this.width < s.lg
  }

  get isLG() {
    return this.width >= s.lg && this.width < s.xl
  }

  get isXL() {
    return this.width >= s.xl
  }

  //
  get isMobile() {
    return isMobile || this.maxSM
  }

  get isMobileSize() {
    return this.maxSM
  }

  get isTablet() {
    return isTablet || this.maxMD
  }

  get isTabletSize() {
    return this.maxMD
  }

  //
  get minXS() {
    return this.width >= s.xs
  }

  get minSM() {
    return this.width >= s.sm
  }

  get minMD() {
    return this.width >= s.md
  }

  get minLG() {
    return this.width >= s.lg
  }

  get minXL() {
    return this.width >= s.xl
  }

  //
  get maxXS() {
    return this.width < s.xs
  }

  get maxSM() {
    return this.width < s.sm
  }

  get maxMD() {
    return this.width < s.md
  }

  get maxLG() {
    return this.width < s.lg
  }

  get maxXL() {
    return this.width < s.xl
  }

  //
  get hMinXS() {
    return this.height >= h.xs
  }

  get hMinSM() {
    return this.height >= h.sm
  }

  get hMinMD() {
    return this.height >= h.md
  }

  get hMinLG() {
    return this.height >= h.lg
  }

  get hMinXL() {
    return this.height >= h.xl
  }

  //
  get hMaxXS() {
    return this.height < h.xs
  }

  get hMaxSM() {
    return this.height < h.sm
  }

  get hMaxMD() {
    return this.height < h.md
  }

  get hMaxLG() {
    return this.height < h.lg
  }

  get hMaxXL() {
    return this.height < h.xl
  }
}

export const $windowSize = new WindowSizeStore()

export const useWindowSize = () => {
  useEffect(() => {
    function handleResize() {
      $windowSize.UPDATE_SIZES()
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
}
