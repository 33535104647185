import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'

import { ChevronDown } from '@carbon/icons-react'
import classNames from 'classnames'

import { useOnClickOutside } from '@src/library/utils/hooks'

import styles from './Dropdown.module.scss'

type IProps = {
  children: ({ onClose }: { onClose: () => void }) => ReactNode
  text: string | ReactNode
  secondaryText?: string | ReactNode
  align?: 'left' | 'right'
  className?: string
  buttonActiveSelector?: string
  buttonClassName?: string
  popupClassName?: string
}

const Dropdown: FC<IProps> = ({
  children,
  text,
  secondaryText,
  align = 'left',
  className,
  buttonClassName,
  buttonActiveSelector = '.active',
  popupClassName,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [hasActive, setHasActive] = useState(false)

  const ref = useOnClickOutside(() => setIsVisible(false))
  const popupRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!buttonActiveSelector) {
      return
    }

    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const activeElements = popupRef.current?.querySelectorAll(buttonActiveSelector)
          setHasActive(activeElements?.length! > 0)
          break
        }
      }
    })

    if (popupRef.current) {
      observer.observe(popupRef.current, {
        attributes: true,
        subtree: true,
        attributeFilter: ['class'],
      })
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  function handleClose() {
    setIsVisible(false)
  }

  return (
    <div
      ref={ref}
      className={classNames(styles.dropdown, isVisible && styles.visible, styles[align], className)}
    >
      <div
        className={classNames(styles.button, buttonClassName, hasActive && 'active')}
        onClick={() => setIsVisible(!isVisible)}
      >
        <div>
          {text}
          {secondaryText}
        </div>
        <ChevronDown className={styles.buttonIcon} />
      </div>
      <div ref={popupRef} className={classNames(styles.popup, popupClassName)}>
        {children({ onClose: handleClose })}
      </div>
    </div>
  )
}

export default Dropdown
